import React, { useState } from 'react'

const Whitelisting = () => {
    const [copy, setCopy] = useState(false)

    const handleCopy = () => {
        navigator.clipboard
            .writeText("163.172.164.206")
            .then(() => {
                setCopy(true);
                setTimeout(() => {
                    setCopy(false); // Hide after 2 seconds
                }, 2000);
            })


            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };
    return (

        <div class="h-full ">
            <div className='bg-white h-full  border border-layout-border shadow-[0px_1px_2px_0px_#0000000D] rounded-lg py-6 px-10'>
                <div className='font-semibold text-3xl'>
                    Whitelisting
                </div>
                <div className='font-medium text-sm text-color-gray-light pt-1'>Whitelist this IP address into your firewall to run tests smoothly</div>
                <div className="flex items-center justify-between  mt-8  p-3 rounded-md border border-link-border">
                    <div className='flex flex-col space-y-[6px]'>
                        <span className="text-color-gray-light font-semibold text-base">
                            163.172.164.206
                        </span>
                        <span className="font-normal text-xs text-input-remark ">
                            Whitelist this IP address into your firewall to run tests smoothly
                        </span>
                    </div>


                    <div className='flex justify-center items-center '>
                      
                            {copy && <span className='text-color-gray-light font-semibold justify-center items-center text-base mx-2'>copied</span>}
                            

                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='cursor-pointer' onClick={handleCopy}>
                                <path d="M7 7V4.2002C7 3.08009 7 2.51962 7.21799 2.0918C7.40973 1.71547 7.71547 1.40973 8.0918 1.21799C8.51962 1 9.08009 1 10.2002 1H15.8002C16.9203 1 17.4801 1 17.9079 1.21799C18.2842 1.40973 18.5905 1.71547 18.7822 2.0918C19.0002 2.51962 19.0002 3.07967 19.0002 4.19978V9.7998C19.0002 10.9199 19.0002 11.48 18.7822 11.9078C18.5905 12.2841 18.2839 12.5905 17.9076 12.7822C17.4802 13 16.921 13 15.8031 13H13M7 7H4.2002C3.08009 7 2.51962 7 2.0918 7.21799C1.71547 7.40973 1.40973 7.71547 1.21799 8.0918C1 8.51962 1 9.08009 1 10.2002V15.8002C1 16.9203 1 17.4801 1.21799 17.9079C1.40973 18.2842 1.71547 18.5905 2.0918 18.7822C2.5192 19 3.07899 19 4.19691 19H9.80355C10.9215 19 11.4805 19 11.9079 18.7822C12.2842 18.5905 12.5905 18.2839 12.7822 17.9076C13 17.4802 13 16.921 13 15.8031V13M7 7H9.8002C10.9203 7 11.4801 7 11.9079 7.21799C12.2842 7.40973 12.5905 7.71547 12.7822 8.0918C13 8.51921 13 9.079 13 10.1969L13 13" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        


                    </div>


                </div>


            </div>




        </div>
    )
}

export default Whitelisting