import React from 'react'
import AnonymousTestInfo from '../components/Share Test/AnonymousTestInfo'

const ShareTestRun = () => {
    return (
        <>
            <div className='anonymous-view-page lg:mx-48'>
                <AnonymousTestInfo />
            </div>
        </>

    )
}

export default ShareTestRun