


// import React, { useEffect, useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { baseUrl } from '../../config';
// const SettingSidebar = () => {
//     const [websites, setWebsites] = useState([]);
//     const [copy, setCopy] = useState("copy");

//     const [selectedWebsite, setSelectedWebsite] = useState(null);
//     const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//     const [activeIndex, setActiveIndex] = useState(0);
//     const navigate = useNavigate();
//     const userId = localStorage.getItem('userId');

//     const handleAddWebsite = () => {
//         localStorage.removeItem("latestWebsiteId")
//         localStorage.removeItem("latestWebsiteUrl")
//         setIsDropdownOpen(false)
//         navigate("/addWebsite")
//     }

//     const handleLogout = () => {
//         localStorage.removeItem("username");
//         localStorage.removeItem("userId");
//         localStorage.removeItem("Token");
//         localStorage.removeItem("isAuth");
//         localStorage.removeItem('selectedWebsiteId');
//         navigate("/signIn");
//     };

//     const handleCopy = () => {
//         navigator.clipboard
//             .writeText("163.172.164.206")
//             .then(() => {
//                 setCopy("copied");
//             })
//             .catch((err) => {
//                 console.error("Failed to copy: ", err);
//             });
//     };


//     const fetchWebsites = async () => {
//         try {
//             const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
//             console.log("response from fetchwebsite", response.data.websites);
//             setWebsites(response.data.websites);

//             // Retrieve the last selected website ID from local storage
//             const storedWebsiteId = localStorage.getItem('selectedWebsiteId');
//             if (storedWebsiteId) {
//                 // Find and set the previously selected website
//                 const storedWebsite = response.data.websites.find(
//                     (website) => website._id === storedWebsiteId
//                 );
//                 if (storedWebsite) {
//                     setSelectedWebsite(storedWebsite);
//                 } else {
//                     // If not found, default to the last website
//                     setSelectedWebsite(response.data.websites.at(-1));
//                 }
//             } else {
//                 // Default to the last website if no previous selection is stored
//                 setSelectedWebsite(response.data.websites.at(-1));
//             }
//         } catch (error) {
//             console.log('Error fetching websites:', error.response);
//         }
//     };
//     const trimUrl = (url) => {
//         return url.length > 14 ? `${url.substring(0, 13)}` : url;
//     };

//     const toggleDropdown = () => {
//         setIsDropdownOpen(!isDropdownOpen);
//     };

//     const handleWebsiteSelect = (website) => {
//         setSelectedWebsite(website);
//         localStorage.setItem('selectedWebsiteId', website._id);
//         setIsDropdownOpen(false);
//         setActiveIndex(0); // Update active index if needed
//         navigate(`/${website?._id}/footprint-all-list`);
//     };



//     useEffect(() => {
//         if (userId) {
//             fetchWebsites();
//         }
//     }, [userId]);

//     return (
//         <div className="wrapper">
//             <div className="flex flex-col h-screen w-64  text-white fixed  rounded-tr-lg rounded-br-lg">

//                 <nav className="flex-grow gap-4 overflow-y-auto relative  ">
//                     <ul className=" text-color-gray-light font-bold ">


//                         {websites.length > 0 &&

//                             <li  >
//                                 <a

//                                     className={`header-section  py-6 px-4 border-b border-b-1 text-color-gray-light flex items-center justify-between  ${activeIndex === 0
//                                         ? 'bg-white '
//                                         : 'hover:bg-white'
//                                         }`}
//                                 >
//                                     <div className="flex items-center">

//                                         <span className="cursor-pointer text-base">
//                                             <Link to={`/${selectedWebsite?._id}/footprint-all-list`} onClick={() => setActiveIndex(0)}>
//                                                 {selectedWebsite?.url ? trimUrl(selectedWebsite.url) : ''}
//                                             </Link>
//                                         </span>
//                                     </div>

//                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
//                                         className={`fa-solid fa-caret-down cursor-pointer transition-transform ${isDropdownOpen ? 'rotate-180' : ''
//                                             }`}
//                                         onClick={toggleDropdown}

//                                     >

//                                         <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="#616161" />
//                                         <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
//                                         <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
//                                         <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
//                                         <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
//                                     </svg>

//                                 </a>

//                                 {isDropdownOpen && (
//                                     <ul
//                                         className="absolute z-10 bg-layout w-full  "
//                                     >
//                                         <div
//                                             className="max-h-[240px] overflow-y-auto"
//                                             style={{
//                                                 maxHeight: websites.length > 5 ? '200px' : 'auto',
//                                                 overflowY: websites.length > 5 ? 'scroll' : 'visible'
//                                             }}
//                                         >
//                                             {websites.map((website) => (
//                                                 <li key={website._id} className="p-3 flex justify-center items-center hover:bg-white">
//                                                     <span
//                                                         onClick={() => handleWebsiteSelect(website)}
//                                                         className="cursor-pointer"
//                                                     >
//                                                         {trimUrl(website.url)}
//                                                     </span>
//                                                 </li>
//                                             ))}
//                                         </div>
//                                         <li className=" flex justify-center px-6 mb-4 mt-2 ">
//                                             <button
//                                                 onClick={handleAddWebsite}
//                                                 className="rounded-lg text-xs font-normal bg-button-bg py-2.5 border border-[#414141] w-full text-white hover:bg-button-hover-color"
//                                             >
//                                                 Add Website
//                                             </button>
//                                         </li>
//                                     </ul>
//                                 )}
//                             </li>


//                         }

//                         <li >
//                             <a
//                                 className={`flex items-center py-6 px-4  border-b border-b-1
//                      ${activeIndex === 2
//                                         ? 'bg-white  '
//                                         : 'hover:bg-white'
//                                     }`}
//                             >






//                                 <Link to="/setting/account">
//                                     <span
//                                         className=" cursor-pointer text-base "
//                                         onClick={() => setActiveIndex(2)}
//                                     >
//                                         Account
//                                     </span>
//                                 </Link>

//                             </a>
//                         </li>

//                         <li >
//                             <a
//                                 className={`flex items-center py-6 px-4  border-b border-b-1
//                      ${activeIndex === 3
//                                         ? 'bg-white  '
//                                         : 'hover:bg-white'
//                                     }`}
//                             >






//                                 <Link to="/setting/notifications">
//                                     <span
//                                         className=" cursor-pointer text-base"
//                                         onClick={() => setActiveIndex(3)}
//                                     >
//                                         Notification
//                                     </span>
//                                 </Link>

//                             </a>
//                         </li>



//                         <li >
//                             <a
//                                 className={`flex items-center py-6 px-4  border-b border-b-1
//                      ${activeIndex === 4
//                                         ? 'bg-white  '
//                                         : 'hover:bg-white'
//                                     }`}
//                             >






//                                 <Link to="/setting">
//                                     <span
//                                         className=" cursor-pointer text-base"
//                                         onClick={() => setActiveIndex(4)}
//                                     >
//                                         Integration
//                                     </span>
//                                 </Link>

//                             </a>
//                         </li>

//                         <li>
//                             <a className="flex items-center  py-6 px-4 border-b border-b-1  hover:bg-white  cursor-pointer">

//                                 <span className="cursor-pointer text-base " onClick={handleLogout}>
//                                     Logout
//                                 </span>
//                             </a>
//                         </li>
//                     </ul>
//                 </nav>

//             </div>
//         </div>
//     );
// };

// export default SettingSidebar;





import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../config';
import Button from '../Button/Button';
const Sidebar = () => {
    const [websites, setWebsites] = useState([]);
    const [copy, setCopy] = useState("copy");
    const location = useLocation();
    const [selectedWebsite, setSelectedWebsite] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const navigate = useNavigate();
    const userId = localStorage.getItem('userId');
    const storedWebsiteId = localStorage.getItem('selectedWebsiteId');
    console.log(storedWebsiteId, "check")

    const handleAddWebsite = () => {
        localStorage.removeItem("latestWebsiteId")
        localStorage.removeItem("latestWebsiteUrl")
        setIsDropdownOpen(false)
        navigate("/addWebsite")
    }

    const handleLogout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("userId");
        localStorage.removeItem("Token");
        localStorage.removeItem("isAuth");
        localStorage.removeItem('selectedWebsiteId');
        navigate("/signIn");
    };

    const handleCopy = () => {
        navigator.clipboard
            .writeText("163.172.164.206")
            .then(() => {
                setCopy("copied");
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };


    // const fetchWebsites = async () => {
    //     try {
    //         const response = await axios.get(`${baseUrl}/websites/user/${userId}`);
    //         console.log("response from fetchwebsite", response.data.websites);
    //         setWebsites(response.data.websites);

    //         // Retrieve the last selected website ID from local storage
    //         const storedWebsiteId = localStorage.getItem('selectedWebsiteId');
    //         if (storedWebsiteId) {
    //             // Find and set the previously selected website
    //             const storedWebsite = response.data.websites.find(
    //                 (website) => website._id === storedWebsiteId
    //             );
    //             if (storedWebsite) {
    //                 setSelectedWebsite(storedWebsite);
    //             } else {
    //                 // If not found, default to the last website
    //                 setSelectedWebsite(response.data.websites.at(-1));
    //             }
    //         } else {
    //             // Default to the last website if no previous selection is stored
    //             setSelectedWebsite(response.data.websites.at(-1));
    //         }
    //     } catch (error) {
    //         console.log('Error fetching websites:', error.response);
    //     }
    // };
    const trimUrl = (url) => {
        return url.length > 14 ? `${url.substring(0, 13)}` : url;
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleWebsiteSelect = (website) => {
        setSelectedWebsite(website);
        localStorage.setItem('selectedWebsiteId', website._id);
        setIsDropdownOpen(false);
        setActiveIndex(0); // Update active index if needed
        navigate(`/${website?._id}/footprint-all-list`);
    };



    // useEffect(() => {
    //     if (userId) {
    //         fetchWebsites();
    //     }
    // }, [userId]);

    return (
        <div className="wrapper">
            <div className='flex flex-col'>
                <Link to={`/${storedWebsiteId}/dashboard`}>
                    <div className=" flex   items-center text-color-gray-light">

                        <svg
                            width="36"
                            height="36"
                            viewBox="0 0 40 40"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_7594_235054)">
                                <path
                                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                                    fill="#616161"
                                />
                                <path
                                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                />
                                <path
                                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                />
                                <path
                                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                />
                                <path
                                    d="M24.4852 14.3429L23.071 12.9287L16 19.9998L23.0711 27.0708L24.4853 25.6566L18.8284 19.9998L24.4852 14.3429Z"
                                    fill="black"
                                    fill-opacity="0.2"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_7594_235054">
                                    <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                        transform="translate(8 8)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>

                        <div className='font-semibold text-xl text-color-gray-light '>Back to Dashboard</div>

                    </div>
                </Link>
                <div className='font-semibold text-color-gray-light text-4xl my-5'>Settings</div>

            </div>

            <div className="flex flex-col h-full w-64 lg:w-80 xl:w-[360px]  text-white   rounded-tr-lg rounded-br-lg">  {/** fixed remove here */}

                <nav className="flex-grow gap-4 overflow-y-auto relative  ">
                    <ul className=" text-color-gray-light font-bold  ">




                        <Link to="/setting/account">
                            <li >
                                <a
                                    className={`flex items-center py-4 px-4 xl:py-4 border-b border-b-1 rounded-lg  border-link-border 
                     ${location.pathname === "/setting/account"
                                            ? 'bg-white  '
                                            : 'hover:bg-white'
                                        }`}
                                >







                                    <span
                                        className=" cursor-pointer text-base "
                                        onClick={() => setActiveIndex(2)}
                                    >
                                        Account
                                    </span>

                                </a>
                            </li>
                        </Link>

                        <Link to="/setting/organisation">
                            <li >
                                <a
                                    className={`flex items-center py-4 px-4 xl:py-4 border-b border-b-1 rounded-lg  border-link-border 
                     ${location.pathname === "/setting/organisation"
                                            ? 'bg-white  '
                                            : 'hover:bg-white'
                                        }`}
                                >






                                    <span
                                        className=" cursor-pointer text-base "
                                        onClick={() => setActiveIndex(2)}
                                    >
                                        Organisation
                                    </span>


                                </a>
                            </li>
                        </Link>
                        <Link to="/setting/notifications">
                            <li >
                                <a
                                    className={`flex items-center py-4 px-4 xl:py-4 border-b border-b-1 rounded-lg  border-link-border 
                     ${location.pathname === "/setting/notifications"
                                            ? 'bg-white  '
                                            : 'hover:bg-white'
                                        }`}
                                >







                                    <span
                                        className=" cursor-pointer text-base "
                                        onClick={() => setActiveIndex(2)}
                                    >
                                        Notifications
                                    </span>


                                </a>
                            </li>
                        </Link>

                        <Link to="/setting/Integration">

                            <li >
                                <a
                                    className={`flex items-center py-4 px-4 xl:py-4  rounded-lg border-b border-b-1 border-link-border 
                     ${location.pathname === "/setting/Integration"
                                            ? 'bg-white  '
                                            : 'hover:bg-white'
                                        }`}
                                >







                                    <span
                                        className=" cursor-pointer text-base"
                                        onClick={() => setActiveIndex(3)}
                                    >
                                        Integrations
                                    </span>


                                </a>
                            </li>
                        </Link>

                        <Link to="/setting/url">

                            <li >
                                <a
                                    className={`flex items-center py-4 px-4 xl:py-4 rounded-lg border-b border-b-1 border-link-border 
                     ${location.pathname === "/setting/url"
                                            ? 'bg-white  '
                                            : 'hover:bg-white'
                                        }`}
                                >







                                    <span
                                        className=" cursor-pointer text-base"
                                        onClick={() => setActiveIndex(4)}
                                    >
                                        Third party URLs
                                    </span>


                                </a>
                            </li>
                        </Link>

                        <Link to="/setting/whitelisting">

                            <li>
                                <a
                                    className={`flex items-center py-4 px-4 xl:py-4 rounded-lg border-b border-b-1 border-link-border 
                     ${location.pathname === "/setting/whitelisting"
                                            ? 'bg-white  '
                                            : 'hover:bg-white'
                                        }`}
                                >

                                    <span className="cursor-pointer text-base ">
                                        Whitelisting
                                    </span>


                                </a>

                            </li>
                        </Link>
                    </ul>
                </nav>

            </div>

        </div >
    );
};

export default Sidebar;