

import React, { useEffect, useRef, useState } from "react";

import { FeedbackFish } from "@feedback-fish/react";
import axios from "axios";
import Intercom from "@intercom/messenger-js-sdk";
import { baseUrl } from "../../config";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
const Nav = () => {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hideResult, setHideResult] = useState(false);
  const searchAreaRef = useRef(null);
  Intercom({
    app_id: "x5w0eskb",
    user_id: user?._id,
    name: user?.username,
    email: user?.email,
    created_at: user?.createdAt,
  });

  const fetchUserById = async () => {
    try {
      const response = await axios.get(`${baseUrl}/user/${userId}`);
      console.log("response from userDetails", response.data);
      setUser(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const performSearch = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/search?q=${searchQuery}&userId=${userId}`
      );
      console.log("searchResult", response.data);
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        performSearch();
      } else {
        setSearchResults([]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);
  useEffect(() => {
    fetchUserById();
  }, []);

  const handleClickResult = (result) => {
    const data = {
      footprintId: result?._id,
      websiteId: result?.website,
    };
    navigate(`/footprint-list`, { state: data });
    setHideResult(true);
    setSearchQuery(result.name);
  };

  const handleSearchQuery = (e) => {
    setHideResult(!hideResult);
    setSearchQuery(e.target.value);
  };



  const TagsDisplay = ({ tags }) => {
    // Join the tags array into a single string with spaces
    const joinedTags = tags.join(" , ");

    return (
      <p className="text-xs text-gray-600 truncate">
        {joinedTags}
        <span className="ml-2 text-gray-300">tags</span>
      </p>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchAreaRef.current &&
        !searchAreaRef.current.contains(event.target)
      ) {
        setHideResult(true); // Hide results if clicked outside search area
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchAreaRef]);

  function getInitials(username) {
    if (!username || typeof username !== "string") {
      return ""; // Return an empty string for invalid input
    }

    const parts = username.trim().split(" "); // Split the username by spaces

    if (parts.length > 1) {
      // If there are multiple parts, return the first and last parts combined
      return parts[0][0].toUpperCase() + parts[parts.length - 1][0].toUpperCase();
    } else {
      // If there's only one part, return the first capital letter
      return username[0].toUpperCase();
    }
  }


  const websiteId = localStorage.getItem("selectedWebsiteId");

  return (
    <>
      <div className="bg-button-bg-nav sticky top-0 z-50">
        <div className="wrapper">
          <div className="navbar bg-white px-4 lg:px-20 py-3  border-b border-link-border">
            <div className={`inner-wrapper flex ${userId ? "justify-center" : "justify-between"
              } md:justify-between items-center `}>
              <div className="logo">

                <div className="logo ">
                  <div className="flex items-center  gap-2">

                    <svg width="33" height="33" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.54286 3.77143H60.3429V61.2857H7.54286V3.77143Z" fill="white" />
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 11.3143C0 5.06558 5.06558 0 11.3143 0H54.6857C60.9344 0 66 5.06558 66 11.3143V54.6857C66 60.9344 60.9344 66 54.6857 66H11.3143C5.06558 66 0 60.9344 0 54.6857V11.3143ZM14.1429 15.1777C14.1429 13.1486 14.6807 11.6831 15.7563 10.7813C16.8768 9.87949 18.3782 9.42857 20.2605 9.42857H45.7396C47.7564 9.42857 49.2578 9.92458 50.2438 10.9166C51.3195 11.8635 51.8573 13.2839 51.8573 15.1777C51.8573 17.1167 51.3195 18.5596 50.2438 19.5065C49.2578 20.4985 47.7564 20.9945 45.7396 20.9945H39.8909C37.7844 21.0396 35.9917 21.2876 34.5127 21.7385C33.0337 22.1894 31.7788 22.7982 30.748 23.5647C29.762 24.3313 28.9776 25.2331 28.395 26.2702C27.8124 27.3073 27.3866 28.412 27.1177 29.5844V20.9945H20.2605C18.2437 20.9945 16.7423 20.4985 15.7563 19.5065C14.6807 18.5596 14.1429 17.1167 14.1429 15.1777ZM38.8118 49.3343C38.8118 50.3263 38.6998 51.2732 38.4757 52.175C38.2964 53.0318 37.9827 53.7983 37.5345 54.4747C37.0863 55.106 36.4813 55.6245 35.7194 56.0303C35.0023 56.3911 34.1059 56.5714 33.0303 56.5714C31.9547 56.5714 31.0359 56.3911 30.274 56.0303C29.5121 55.6245 28.8846 55.106 28.3916 54.4747C27.9434 53.7983 27.6073 53.0318 27.3832 52.175C27.2039 51.2732 27.1143 50.3263 27.1143 49.3343V35.401C27.1143 31.7486 28.1451 28.9304 30.2067 26.9464C32.2684 24.9624 35.1367 23.9027 38.8118 23.7674V49.3343Z" fill="#5049AC" />
                    </svg>

                    <span className="font-bold text-color-gray-light ">TestTube</span>
                  </div>

                </div>

              </div>


              {
                userId != null ? (
                  <div className="hidden md:flex items-center gap-2">
                    <FeedbackFish projectId="b5c3da700bc1a7" userId={user?.email}>
                      <button className="button bg-layout text-color-gray-light text-xs font-normal rounded-lg p-1.5 flex items-center">
                        <span>Share Feedback</span>
                      </button>
                    </FeedbackFish>
                    <div className="cicle-nick-name rounded-full bg-yellow text-[16px] px-3 py-1 font-normal">
                      {getInitials(user?.username)}
                    </div>

                    <span className="text-color-gray-light font-semibold text-lg">
                      {user?.username.split(/(?=[A-Z])/)[0]}
                    </span>
                    {/* <div className="username-tag rounded-2xl border text-blue border-blue px-3 text-xs font-semibold py-1">contributor</div>
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.6666 1L5.99992 5.66667L1.33325 1" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg> */}

                  </div>
                ) : (
                  <div className="flex items-center md:gap-4">

                    <span className="text-color-gray-light hidden text-base font-medium lg:block">Start using TestTube for your Organization?</span>

                    <Button text="Sign up" href="https://app.testtube.io" />



                    <span className="text-color-gray-light font-semibold text-lg">
                    </span>


                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
