
// import React, { useState, useEffect } from 'react';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Decimation,
//   Filler,
// } from 'chart.js';
// import zoomPlugin from 'chartjs-plugin-zoom';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Decimation,
//   Filler,
//   zoomPlugin
// );

// const StockGraph = ({ videoData }) => {
//   const [data, setData] = useState({
//     labels: [],
//     datasets: [
//       {
//         label: 'Stock Status',
//         data: [],
//         fill: false,
//         borderColor: 'rgba(75,192,192,1)',
//         tension: 0.1,
//       },
//     ],
//   });

//   const [timeInterval, setTimeInterval] = useState(1000); // Update every second
//   const [alternatePeak, setAlternatePeak] = useState(0.5); // Default alternation

//   useEffect(() => {
//     const interval = setInterval(() => {
//       const currentTime = new Date();
//       const formattedTime = currentTime.toLocaleTimeString(); // Format time as HH:MM:SS

//       // Check if current time matches any video time
//       const matchingVideo = videoData.find(
//         (video) => video.videoTime === formattedTime
//       );

//       // Determine the y-axis value
//       let yValue = alternatePeak; // Default to alternate peaks
//       if (matchingVideo) {
//         yValue = matchingVideo.videoStatus === 'pass' ? 1 : -1;
//       }

//       // Alternate the default peaks for non-matching times
//       setAlternatePeak((prevPeak) => (prevPeak === 0 ? 0 : 0));

//       setData((prevData) => {
//         const updatedLabels = [...prevData.labels, formattedTime];
//         const updatedData = [...prevData.datasets[0].data, yValue];

//         return {
//           ...prevData,
//           labels: updatedLabels,
//           datasets: [
//             {
//               ...prevData.datasets[0],
//               data: updatedData,
//             },
//           ],
//         };
//       });
//     }, timeInterval);

//     return () => clearInterval(interval); // Clean up on component unmount
//   }, [timeInterval, videoData, alternatePeak]);

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       x: {
//         type: 'category',
//         position: 'bottom',
//         ticks: {
//           maxTicksLimit: 20, // Limit the number of ticks on the x-axis to avoid overcrowding
//         },
//       },
//       y: {
//         min: -1.5,
//         max: 1.5,
//         // ticks: {
//         //   stepSize: 0.5,
//         // },

//         ticks: {
//             // stepSize: 0.5,
//             stepSize: 0,

//             callback: (value) => {
//               // Map y-axis values to labels
//               if (value === 1) return 'Pass';
//               if (value === -1) return 'Fail';
//               return ''; // Leave other values blank
//             },
//           },
//       },
//     },
//     plugins: {
//       decimation: {
//         enabled: true,
//         algorithm: 'lttb',
//         samples: 500,
//       },
//       zoom: {
//         pan: {
//           enabled: true,
//           mode: 'x',
//         },
//         zoom: {
//           enabled: true,
//           mode: 'x',
//           drag: true,
//         },
//       },
//     },
//   };

//   return (
//     <div style={{ width: '100%', height: '400px' }}>
//       <Line data={data} options={options} />
//     </div>
//   );
// };

// export default StockGraph;



//----------------> real time with regular date .


// import React, { useState, useEffect } from 'react';
// import { Line } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Decimation,
//   Filler,
// } from 'chart.js';
// import zoomPlugin from 'chartjs-plugin-zoom';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Decimation,
//   Filler,
//   zoomPlugin
// );

// const StockGraph = ({ videoData }) => {
//   const [data, setData] = useState({
//     labels: [],
//     datasets: [
//       {
//         label: 'Stock Status',
//         data: [],
//         fill: false,
//         borderColor: 'rgba(75,192,192,1)',
//         tension: 0.1,
//         pointRadius: 0, // Remove dots from points
//       },
//     ],
//   });

//   const [timeInterval, setTimeInterval] = useState(1000); // Update every second
//   const [alternatePeak, setAlternatePeak] = useState(0.5); // Default alternation

//   useEffect(() => {
//     const interval = setInterval(() => {
//       const currentTime = new Date();
//       const formattedTime = currentTime.toLocaleTimeString();

//       // Check if current time matches any video time
//       const matchingVideo = videoData.find(
//         (video) => video.videoTime === formattedTime
//       );

//       // Determine the y-axis value
//       let yValue = alternatePeak; // Default alternate peaks
//       if (matchingVideo) {
//         yValue = matchingVideo.videoStatus === 'pass' ? 1 : -1;
//       }

//       setAlternatePeak((prevPeak) => (prevPeak === 0 ? 0 : 0));

//       setData((prevData) => {
//         const updatedLabels = [...prevData.labels, formattedTime];
//         const updatedData = [...prevData.datasets[0].data, yValue];

//         return {
//           ...prevData,
//           labels: updatedLabels,
//           datasets: [
//             {
//               ...prevData.datasets[0],
//               data: updatedData,
//             },
//           ],
//         };
//       });
//     }, timeInterval);

//     return () => clearInterval(interval); // Clean up on unmount
//   }, [timeInterval, videoData, alternatePeak]);

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       x: {
//         type: 'category',
//         position: 'bottom',
//         ticks: {
//           maxTicksLimit: 20,
//         },
//       },
//       y: {
//         min: -1.5,
//         max: 1.5,
//         ticks: {
//           stepSize: 0,
//           callback: (value) => {
//             if (value === 1) return 'Pass';
//             if (value === -1) return 'Fail';
//             return '';
//           },
//         },
//       },
//     },
//     plugins: {
//       decimation: {
//         enabled: true,
//         algorithm: 'lttb',
//         samples: 500,
//       },
//       zoom: {
//         pan: { enabled: true, mode: 'x' },
//         zoom: { enabled: true, mode: 'x', drag: true },
//       },
//     },
//   };

//   return (
//     <div style={{ width: '100%', height: '400px' }}>
//       <Line data={data} options={options} />
//     </div>
//   );
// };


// export default StockGraph





import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Decimation,
  Filler,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Decimation,
  Filler,
  zoomPlugin
);

const StockGraph = ({ videoData }) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Stock Status',
        data: [],
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
        pointRadius: 0, // No dots
      },
    ],
  });

  const [timeInterval, setTimeInterval] = useState(1000); // Update every second
  const [lastStatus, setLastStatus] = useState(null); // Track last status
  const [neutralCounter, setNeutralCounter] = useState(0); // Neutral duration tracker

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      const formattedTime = currentTime.toLocaleTimeString();

      // Check for a matching video event
      const matchingVideo = videoData.find(
        (video) => video.videoTime === formattedTime
      );

      let yValue = lastStatus; // Keep previous status

      if (matchingVideo) {
        yValue = matchingVideo.videoStatus === 'pass' ? 1 : -1;
        setLastStatus(yValue); // Store last status
        setNeutralCounter(0); // Set neutral period to 2 seconds
      } else if (neutralCounter > 0) {
        setNeutralCounter(neutralCounter - 1); // Decrease neutral counter
      } else {
        yValue = 0; // Set to neutral after counter expires
      }

      setData((prevData) => {
        return {
          labels: [...prevData.labels, formattedTime],
          datasets: [
            {
              ...prevData.datasets[0],
              data: [...prevData.datasets[0].data, yValue],
            },
          ],
        };
      });
    }, timeInterval);

    return () => clearInterval(interval);
  }, [timeInterval, videoData, lastStatus, neutralCounter]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'category',
        position: 'bottom',
        ticks: {
          maxTicksLimit: 20,
        },
      },
      y: {
        min: -1.5,
        max: 1.5,
        ticks: {
          stepSize: 0,
          callback: (value) => {
            if (value === 1) return 'Pass';
            if (value === -1) return 'Fail';
            return '';
          },
        },
      },
    },
    plugins: {
      decimation: {
        enabled: true,
        algorithm: 'lttb',
        samples: 500,
      },
      zoom: {
        pan: { enabled: true, mode: 'x' },
        zoom: { enabled: true, mode: 'x', drag: true },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '400px' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default StockGraph;
