import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { baseUrl } from "../../config";

const Input = ({
    formData,
    setFormData,
    footPrint,
    isReadOnly,
    isEditable,
    isButtonEnabled = true,
    
    bg = false, setDetectChange ,onUrlErrorChange ,anonymousData

}) => {
    const [footprintNameError, setFootprintNameError] = useState("");
    const [enableError, setEnableError] = useState(false)
    const [tagsArray, setTagsArray] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const [urlError, setUrlError] = useState("");

    // console.log("formData for edit", formData.tags, tagsArray);



    const websiteId = localStorage.getItem("selectedWebsiteId")



    console.log("ye input k ander data" ,anonymousData)

    const checkFootprintUnique = async (value) => {

        const response = await axios.post(`${baseUrl}/footprint/checkFootprint/${websiteId}`, { value });
        console.log("reponse from footprintNameUnique", response)
        return response.data?.isUnique;

    }


    // Custom debounce function
    function debounce(func, delay) {
        let timer;
        return (...args) => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }


    // Define the debounced function using useCallback, to ensure it is not re-created
    const debouncedCheckUnique = useCallback(
        debounce(async (value) => {
            const isUnique = await checkFootprintUnique(value);
            if (!isUnique) {
                setFootprintNameError("Test name must be unique!");
            }
        }, 500),
        [] // Empty array ensures it is created once
    );

    const handleChange = async (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setErrorMessage("");
        if (setDetectChange) {
            setDetectChange(true);
        }

        if (name === "footprintName") {
            // Reset error message first
            setFootprintNameError("");
            // Debounce the API call to check uniqueness
            if (value.length >= 30) {
                setFootprintNameError("Test name cannot exceed 30 characters.");
                return;
            }


            debouncedCheckUnique(value);
        }

        // if (name === "footprintUrl") {
        //     setEnableError(true)

        // }

        if (name === "footprintUrl") {
            setEnableError(true);

            // Validate if URL starts with http:// or https://
            if (!/^https?:\/\//.test(value)) {
                setUrlError("URL must start with http:// or https://");
                onUrlErrorChange(true); 

            } else {
                setUrlError(""); // Clear error if valid
                onUrlErrorChange(false);
            }
        }



    };

    const handleKeyDown = (event) => {
        const { value } = event.target;

        if (event.key === "Enter" && value.trim() !== "") {
            event.preventDefault();
            // Add tag if "Enter" or "Space" is pressed and value is not empty

            if (!tagsArray.includes(value.trim())) {

                setTagsArray([...tagsArray, value.trim()]);

                // Add tag to the tagsArray in formData
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    tagsArray: [...prevFormData.tagsArray, value.trim()], // Add new tag
                    tags: "", // Clear the input field
                }));
                setErrorMessage("");

            }
            else {
                setErrorMessage("Tags Must be unique !");
            }
        }
    };

    const removeTag = (index) => {
        const updatedTags = tagsArray.filter((_, i) => i !== index);
        setTagsArray(updatedTags);

        setFormData((prevFormData) => {
            const updatedTags = prevFormData.tagsArray.filter((_, i) => i !== index);
            return {
                ...prevFormData,
                tagsArray: updatedTags,
            };
        });
    };



    useEffect(() => {
        console.log("mai run ho rha hu", formData)

    }, [formData])

    return (


        <div className="space-y-4 mx-4 py-2 flex flex-col gap-2 ">
            <div className="relative w-full text-[16px]  flex flex-col gap-1.5">
                <label htmlFor="footprintName" className="block  text-[16px] font-semibold text-color-gray-light ">
                    Test Name
                </label>
                <input
                    type="text"
                    id="footprintName"
                    name="footprintName"
                    value={formData.footprintName || anonymousData?.name}
                    disabled={!isEditable}
                    onChange={handleChange}
                    placeholder="example: Sign up"
                    maxLength={30}

                    className={`block w-full p-3 rounded-md border text-color-gray-light border-link-border font-medium text-sm ${bg ? "bg-layout" : "bg-white"} focus:outline-none`}
                />
                <p className="text-xs text-input-remark font-normal">This is the name of the test. Example: Sign up</p>
                {footprintNameError && (
                    <p className="text-[12px] text-[#F7716E] mt-1">
                        {footprintNameError}
                    </p>
                )}
            </div>



            <div className="relative w-full text-[16px] flex flex-col gap-1.5">
                <label htmlFor="footprintUrl" className="block text-sm font-medium  text-color-gray-light">
                    Starting webpage URL
                </label>
                <input
                    type="text"
                    id="footprintUrl"
                    name="footprintUrl"
                    value={footPrint?.footprintUrl ||anonymousData?.footprintUrl}
                    disabled={isReadOnly}

                    onChange={handleChange}
                    placeholder="example: amazon.com/fashion"
                    className={`block w-full p-3 rounded-md border text-sm ${bg ? "bg-disable-button" : "bg-white"} border-link-border focus:outline-none`}
                />
                <p className="text-xs text-input-remark font-normal">This is the page that you want the test to start from. If you page  requires a user to be logged in, start from the authentication page.</p>
                {urlError && (
                    <p className="text-[12px] text-[#F7716E] mt-2">
                        {urlError}
                    </p>
                )}
                {(!isButtonEnabled && enableError && !urlError) && (
                    <p className="text-[12px] text-[#F7716E] mt-2 ">
                        Test case url should be same as the added website url
                    </p>
                )}
            </div>
            <div className="relative w-full text-[16px] flex flex-col gap-1.5">
                <label htmlFor="tags" className="block text-[16px] font-semibold text-color-gray-light ">
                    Tags
                </label>
                <div className="flex items-center flex-wrap gap-2 px-2 pb-2 pt-2 rounded-md border border-link-border focus-within:outline-none">
                    {/* {((formData?.tagsArray && !formData.tagsArray[0]=="") || anonymousData?.tagsArray) */}
                    
                    {(formData?.tagsArray?.length > 0 
                        ? formData.tagsArray.filter(tag => tag.trim() !== "") 
                        : anonymousData?.tagsArray ?? []
                      ).map((tag, index) => (

                        <div
                            key={index}
                            className="bg-purple rounded-full px-3 py-1 border font-semibold border-purple-border text-xs text-purple flex items-center justify-center gap-1.5"
                        >
                            <span>{tag}</span>
                            {isEditable && (
                                // <svg
                                //     width="10"
                                //     height="10"
                                //     viewBox="0 0 10 10"
                                //     fill="none"
                                //     xmlns="http://www.w3.org/2000/svg"
                                //     className="ml-1 cursor-pointer"
                                //     onClick={() => removeTag(index)}
                                // >
                                //     <path
                                //         d="M1.14979 0.207469C0.88944 -0.0528803 0.46733 -0.0528803 0.206981 0.207469C-0.0533686 0.467819 -0.0533686 0.889929 0.206981 1.15028L4.0568 5.0001L0.207021 8.84988C-0.0533286 9.11023 -0.0533289 9.53234 0.207021 9.79269C0.46737 10.053 0.88948 10.053 1.14983 9.79269L4.99961 5.94291L8.8494 9.79269C9.10975 10.053 9.53186 10.053 9.79221 9.79269C10.0526 9.53234 10.0526 9.11023 9.79221 8.84988L5.94242 5.0001L9.79225 1.15028C10.0526 0.889929 10.0526 0.467819 9.79225 0.207469C9.5319 -0.0528803 9.10979 -0.0528803 8.84944 0.207469L4.99961 4.05729L1.14979 0.207469Z"
                                //         fill="#616161"
                                //     />
                                // </svg>

                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 cursor-pointer" onClick={() => removeTag(index)} width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path></svg>
                            )}
                        </div>
                    ))}
                    {isEditable && (
                        <input
                            type="text"
                            id="tags"
                            name="tags"
                            value={formData.tags}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            placeholder={formData?.tagsArray.length == 0 ? "example: Sign up" : ""}


                            className={`flex-grow block w-auto min-w-[10px] p-1 rounded-md focus:ring-0 focus:outline-none ${bg ? "bg-layout" : ""} `}
                        />
                    )}

                </div>
                <p className="text-xs text-input-remark font-normal ">These are tags that you can enter. Tags will help you find the relevant tests.</p>

                {errorMessage && (
                    <p className="text-[12px] text-[#F7716E] mt-1">
                        {errorMessage}
                    </p>
                )}
            </div>
        </div>

    );
};

export default Input;
