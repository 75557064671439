import React, { useState, useEffect } from "react";
import Test_Schedule_Card from "../Card/Test_Schedule_Card";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { baseUrl } from "../../config";
import Test_Home_Card from "../Card/Test_Home_Card";
import axios from 'axios';
import ExtensionAlert from "../Card/ExtensionAlert";

const Test_Schedule = ({isExtensionInstalled}) => {
  const [topSection, setTopSection] = useState(false);
  // const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  // const [showExtensionConfirm, setShowExtensionConfirm] = useState(false);
  const { footprintId, websiteId } = useParams();
  const token = localStorage.getItem("Token")
  const location = useLocation();
  const { isRestart } = location.state || "";
  const isReadOnly = false;
  const status = "active";


  const [isFilled, setIsFilled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showScheduleModel, setShowScheduleModel] = useState(false);
  const [footPrint, setFootprint] = useState();
  const navigate = useNavigate();

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };
  const [formData, setFormData] = useState({
    footprintName: "",
    tags: "",
    tagsArray: [],
  });

  const toggleIcon = () => {
    setIsFilled(!isFilled);
  };



  const handleSaveSchedule = () => {
    const data = { websiteId: websiteId };
    navigate("/footprint-status-list", { state: data });
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const fetchFootprintById = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/footprint/foot/${footprintId}`,

        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      console.log("response from footprint by Id", response.data);
      setFootprint(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  useEffect(() => {
    if (footprintId) {
      fetchFootprintById();
    }
  }, [footprintId]);

  useEffect(() => {
    if (footPrint) {
      let Tags = footPrint?.tags;
      let footprintTags = Tags?.join(", ");
      setFormData({
        footprintName: footPrint?.name || "",
        tags: "",
        tagsArray: footPrint?.tagsArray,
      });
    }
  }, [footprintId, footPrint]);

  // Extension check

  // useEffect(() => {
  //   const handleLoad = () => {
  //     console.log("Page fully loaded");

  //     const hasShownConfirmation = localStorage.getItem("hasShownConfirmation");
  //     const messageListener = (event) => {
  //       if (event.source !== window) return;
  //       if (event.data.type === "EXTENSION_INSTALLED") {
  //         console.log("Extension is installed:", event.data.data);
  //         setIsExtensionInstalled(true);
  //         if (!hasShownConfirmation) {
  //           setShowExtensionConfirm(true);
  //           localStorage.setItem("hasShownConfirmation", "true");
  //         }
  //       }
  //     };

  //     // Add event listener for messages
  //     window.addEventListener("message", messageListener);

  //     // Send a message to check if the extension is installed
  //     console.log("Sending CHECK_EXTENSION message");
  //     window.postMessage({ type: "CHECK_EXTENSION" }, "*");

  //     // Clean up event listener on component unmount
  //     return () => {
  //       window.removeEventListener("message", messageListener);
  //     };
  //   };

  //   // Check if document is already loaded
  //   if (document.readyState === 'complete') {
  //     handleLoad();
  //   } else {
  //     window.addEventListener('load', handleLoad);

  //     // Clean up the load event listener
  //     return () => {
  //       window.removeEventListener('load', handleLoad);
  //     };
  //   }
  // }, []);

  return (
    <>
      <div className="header-section">
        <div className="mb-2">
          {/* {!isExtensionInstalled && <ExtensionAlert />} */}

        </div>

        <div className="info border border-layout-border px-6 rounded-lg bg-white">
          {!topSection && (
            <div className="guide-info font-bold text-color-gray-light inline-flex gap-4 items-center mx-4 my-4 cursor-pointer" onClick={()=> navigate(`/${websiteId}/test-list`)}>
           
                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 9L1 5L5 1" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

              <h2 className=" font-bold text-[20px] cursor-pointer" >Back To All Tests</h2>
            </div>
          )}

          <div className="setup-guide "> {/** mt-4 removed */}
            <div className="inner-setup-guide text-color-gray-light">
              {
                !footPrint ? (

                  <Test_Home_Card websiteId={websiteId} isExtensionInstalled={isExtensionInstalled} />
                ) : (

                  <Test_Schedule_Card
                    status={isRestart}
                    footPrint={footPrint}
                    websiteId={websiteId}
                    isReadOnly={true}
                    isEditable={true}
                    formData={formData}
                    setFormData={setFormData}
                  />

                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test_Schedule;
