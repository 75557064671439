
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer/Footer";
import Nav from "../Navbar/Nav";




const Layout = () => {
  const location = useLocation();
 

  // const hideSidebarPaths = ["/onboarding", "/addWebsite" ];
  const hideSidebarPaths = [
    "/onboarding",
    "/addWebsite",
    /^\/share-test\/website\/[^/]+\/footprint\/[^/]+\/video\/[^/]+$/
  ];
  
  const shouldHideSidebar = hideSidebarPaths.some(path => 
    path instanceof RegExp ? path.test(location.pathname) : path === location.pathname
  );
  


  return (
    <div className="flex flex-col min-h-screen bg-layout">

      <Nav />

      {/* <div className=" mx-20 py-2 gap-6 flex  flex-1 overflow-hidden">
        {!isExtensionInstalled && !shouldHideSidebar && <ExtensionAlert />}
      </div> */}
      {/* Content area with sidebar and main content mx-16 */}
      <div className="outer-section bg-white md:bg-layout   md:mx-20 py-6 gap-6 flex  flex-1 overflow-hidden ">

        {!shouldHideSidebar && <Sidebar />}

        <main className="flex-1   overflow-y-auto">
          {/* {children} */}
          <Outlet />
        </main>

      </div>

      <Footer />
    </div>
  );
};

export default Layout;



