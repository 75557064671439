import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FailToast from "../Toast/FailToast";
import SuccessToast from "../Toast/SuccessToast";
import axios from "axios";
import { baseUrl } from "../../config";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Loading from "../Loading/Loading";
import OtpModal from "../Modal/OtpModal";
import SettingButton from "../Button/SettingButton";

const Account = () => {
  const [errors, setErrors] = useState({ name: "", oldPassword: "", newPassword: "", phoneNumber: "" });
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [value, setValue] = useState("");
  const [user, setUser] = useState();
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [detectChange, setDetectChange] = useState(false);
  const [onboardingId, setOnboardingId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const [passwordFormData, setPasswordFormData] = useState({
    oldPassword: "",
    newPassword: "",
  });



  const userId = localStorage.getItem("userId");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 4;
  };

  

  const validateUsername = (username) => {
    // Regex to ensure at least 2 alphabetic characters exist and allow unlimited characters
    const usernameRegex = /^(?=(?:.*[a-zA-Z]){2,}).+$/;

    return usernameRegex.test(username);
  };

  

  // const validatePhoneNumber = (phoneNumber) => {
  //   const phoneRegex = /^(?:\+?-?)?\d{7,}$/; 
  //   return phoneRegex.test(phoneNumber);  
  // };

  const validatePhoneNumber = (phoneNumber) => {
    // Allow +, (, ), -, and spaces, but ensure at least 7 digits
    const phoneRegex = /^[+\d\s()-]*$/; // Matches allowed characters
    const digitCount = phoneNumber.replace(/[^\d]/g, "").length; // Count only digits
  
    // Check if the format is valid and it contains at least 7 digits
    return phoneRegex.test(phoneNumber) && digitCount >= 7;
  };
  

  const handleChange = (event) => {
    console.log("run");
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setDetectChange(true);
  };
  const handlePasswordChange = (event) => {
    console.log("run password cahnge");
    const { name, value } = event.target;
    setPasswordFormData({ ...passwordFormData, [name]: value });
  };



  const handleVerifyEmail = async () => {
    try {
      const response = await axios.post(`${baseUrl}/verify/emailVerifyLink`, {
        email: formData.email,
      });
      console.log("response from verify email", response.data.message);
      // alert(response.data.message);
      setActiveSuccess(true);
      setValue(response.data.message);


    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const handleVerifyPhone = async () => {
    let phone = "+" + formData.mobile;

    try {
      const response = await axios.post(`${baseUrl}/verify/phoneVerifyCode`, {
        phoneNumber: phone,
      });
      console.log("response from verify phone", response.data.message);
      alert(response.data.message);
      setIsModalOpen(true);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const handleSubmit = () => {

    setIsLoading(true)
    if (!formData.name) {
      setErrors({ name: "Name cannot be empty" })
      setIsLoading(false)
      return;
    }

    const nameError = validateUsername(formData.name)
      ? ""
      : "Name must have at least 2 character"


    const phoneError = formData.mobile && !validatePhoneNumber(formData.mobile)
    ? "Invalid Phone Number"
    : "";
    if (nameError) {
      setErrors({ name: nameError });
      setIsLoading(false)
      return;
    }
    else if (phoneError) {
      setErrors({ phoneNumber: phoneError });
      setIsLoading(false)
      return;
    }
    else {
      updateAccount();



    }
  };

  const handlePasswordSubmit = async () => {
    setIsLoading(true)
    if (!passwordFormData.newPassword && !passwordFormData.oldPassword) {
      setErrors({ oldPassword: "Old password cannot be empty", newPassword: "New password cannot be empty" })
      setIsLoading(false)
      return;
    }
    if (!passwordFormData.newPassword) {
      setErrors({ newPassword: "New password cannot be empty" })
      setIsLoading(false)
      return;
    }
    if (!passwordFormData.oldPassword) {
      setErrors({ oldPassword: "Old password cannot be empty" })
      setIsLoading(false)
      return;
    }
    if (!validatePassword(passwordFormData.newPassword)) {
      setActiveFail(true);
      setValue("Oops! Your new password should be at least 4 characters long");
      setIsLoading(false)
      return;
    } else {
      const userId = localStorage.getItem("userId");
      try {
        setErrors({ oldPassword: "", newPassword: "" })
        const response = await axios.post(`${baseUrl}/user/changePassword`, {
          formData: passwordFormData,
          userId: userId,
        });
        console.log("response from change Password", response.data);
        setActiveSuccess(true);
        setValue(response.data.message);
        setIsLoading(false)
        return;
      } catch (error) {
        setActiveFail(true);
        setValue(error.response.data.message);
        setIsLoading(false)
        console.log("Error in fetching data", error.response);
      }
    }
  };

  const updateAccount = async () => {
    const userId = localStorage.getItem("userId");

    try {
      setErrors({ name: "" })
      const response = await axios.put(`${baseUrl}/user/update-account`, {
        userId: userId,
        onBoardingId: onboardingId,
        formData: formData,
      });
      console.log("updated account details", response.data);
      const newToken = response.data.token;
      // if (newToken) {
      //   localStorage.setItem("token", newToken);
      // }
      if (newToken) {
        localStorage.setItem("Token", response.data.token);
        const decoded = jwtDecode(newToken);
        const { username, userId } = decoded;
        localStorage.setItem("username", username);
        localStorage.setItem("userId", userId);
      }

      setActiveSuccess(true);
      setValue("Details updated successfully");
      setIsLoading(false)

      // alert(response.data.msg);
    } catch (error) {
      // alert(error.response.data.msg);
      setIsLoading(false)
    }
  };

  const fetchUserById = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}/onboard/${userId}`);
      console.log("response from Onboard data", response.data.onboardData);
      setOnboardingId(response.data.onboardData[0]._id);
      setEmailVerified(response?.data.onboardData[0]?.user?.isEmailVerified);
      setPhoneVerified(response?.data.onboardData[0]?.user?.isPhoneVerified);
      setFormData({
        name: response?.data?.onboardData[0]?.user?.username || "",
        email: response?.data?.onboardData[0]?.user?.email || "",
        mobile: response?.data?.onboardData[0]?.mobile || "",
      });
      setIsLoading(false);
    } catch (error) {
      console.log("Error in fetching data", error.response);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserById();
    }
  }, [userId]);

  // removing pop up in 3 second automatically

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 5000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        // navigate("/dashboard");

      }, 3500);
    }
  }, [activeFail, activeSuccess]);
  return (
    // <div className="pb-0 ">
    //   {activeFail && (
    //     <FailToast
    //       errorValue={value}
    //       activeFail={activeFail}
    //       setActiveFail={setActiveFail}
    //     />
    //   )}
    //   {activeSuccess && (
    //     <SuccessToast
    //       successValue={value}
    //       activeSuccess={activeSuccess}
    //       setActiveSuccess={setActiveSuccess}
    //     />
    //   )}
    //   <div
    //     class="flex  flex-col space-y-3  py-2 px-3 border rounded-xl bg-white "
    //     style={{ width: "450px", height: "512px",  }}
    //   >
    //     <OtpModal
    //       show={isModalOpen}
    //       phoneNumber={formData.mobile}
    //       onClose={() => setIsModalOpen(false)}
    //     />

    //     <div class=" mb-1 space-y-1">
    //       <h1 class="text-color-gray-light font-extrabold text-lg">
    //         Account Management
    //       </h1>
    //       <p class="text-color-gray-light font-normal	text-sm	">
    //         Manage your account details from this section{" "}
    //       </p>
    //     </div>

    //     <div className="bg-[#F1F1F1] p-3 rounded-lg space-y-3 mt-5 ">
    //       <div class="relative mt-1 w-full">
    //         <input
    //           type="text"
    //           id="name"
    //           name="name"
    //           value={formData.name}
    //           onChange={handleChange}
    //           class="border-1 peer block w-full appearance-none rounded-lg border border-[#A1A1A1]  bg-white bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] focus:outline-none focus:ring-0"
    //           placeholder=" "
    //         />
    //         <label
    //           for="name"
    //           class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
    //         >
    //           {" "}
    //           Name{" "}
    //         </label>
    //       </div>
    //       {errors.name && (
    //         <p className="text-red-300 font-normal text-xs pt-1">
    //           {errors.name}
    //         </p>
    //       )}


    //       <div className="">
    //         <div className="relative mt-1 w-full">
    //           <input
    //             type="text"
    //             id="email"
    //             name="email"
    //             disabled={true}
    //             value={formData.email}
    //             onChange={handleChange}
    //             className="peer block w-full appearance-none rounded-lg bg-white border border-[#A1A1A1] px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] focus:outline-none focus:ring-0"
    //             placeholder=""
    //           />
    //           <label
    //             htmlFor="email"
    //             className="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
    //           >
    //             Email
    //           </label>

    //           {isLoading ? (
    //             <div className="absolute right-3 top-3">
    //               {" "}
    //               <Loading />
    //             </div>
    //           ) : emailVerified ? (
    //             <span className="absolute right-3 top-3 text-green-500">
    //               Verified
    //             </span>
    //           ) : (

    //              <button
    //              onClick={handleVerifyEmail}
    //              className="absolute right-3  transform -translate-y-[50%] top-[50%] text-[#1672EC] rounded px-2 py-1 text-xs"
    //            >
    //              Verify
    //            </button>
    //           )}
    //         </div>
    //       </div>

    //       <div class="relative mt-1 w-full">
    //         <input
    //           type="text"
    //           id="mobile"
    //           name="mobile"
    //           disabled={true}
    //           value={formData.mobile}
    //           onChange={handleChange}
    //           class="border-1 peer block w-full appearance-none rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] bg-white focus:outline-none focus:ring-0"
    //           placeholder=" "
    //         />
    //         <label
    //           for="mobile"
    //           class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
    //         >
    //           {" "}
    //           Mobile{" "}
    //         </label>
    //         {isLoading ? (
    //           <div className="absolute right-3 top-3">
    //             <Loading />
    //           </div>
    //         ) : phoneVerified ? (
    //           <span className="absolute right-3 top-3 text-green-500">
    //             Verified
    //           </span>
    //         ) : (
    //           <button
    //             onClick={handleVerifyPhone}
    //              className="absolute right-3  transform -translate-y-[50%] top-[50%] text-[#1672EC] rounded px-2 py-1 text-xs"
    //           >
    //             Verify
    //           </button>
    //         )}
    //       </div>
    //       <div class="flex gap-3">
    //         <button
    //           class="py-3 px-3 rounded-lg text-xs font-normal bg-button-bg hover:bg-button-hover text-white"
    //           onClick={handleSubmit}
    //           disabled={!detectChange}
    //         >
    //           Save
    //         </button>
    //       </div>
    //     </div>

    //     <div className="bg-[#F1F1F1] p-3 rounded-lg space-y-3  ">
    //       <div class="relative mt-1 w-full">
    //         <input
    //           type="password"
    //           id="currentpassword"
    //           name="oldPassword"
    //           onChange={handlePasswordChange}
    //           class="border-1 peer block w-full appearance-none bg-white rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] focus:outline-none focus:ring-0"
    //           placeholder=" "
    //         />
    //         <label
    //           for="currentpassword"
    //           class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
    //         >
    //           {" "}
    //           Current Password{" "}
    //         </label>
    //       </div>
    //       <div class="relative mt-1 w-full">
    //         <input
    //           type="password"
    //           id="newPassword"
    //           name="newPassword"
    //           onChange={handlePasswordChange}
    //           class="border-1 peer block w-full bg-white appearance-none rounded-lg border border-[#A1A1A1] bg-transparent px-2.5 pb-2.5 pt-3 text-sm text-gray-900 focus:border-[#A1A1A1] focus:outline-none focus:ring-0"
    //           placeholder=" "
    //         />
    //         <label
    //           for="newPassword"
    //           class="absolute top-2 left-1 z-10 origin-[0] -translate-y-4 scale-75 transform cursor-text select-none bg-white px-2 text-sm text-gray-500 duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2 peer-focus:text-gray-600"
    //         >
    //           {" "}
    //           New Password{" "}
    //         </label>
    //       </div>

    //       <div class="flex gap-3">
    //         <button
    //           class="py-3 px-3 rounded-lg text-xs font-normal bg-button-bg hover:bg-button-hover text-white"
    //           onClick={handlePasswordSubmit}
    //         >
    //           Save
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>

      {activeFail && (
        <FailToast
          errorValue={value}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}
      {activeSuccess && (
        <SuccessToast
          successValue={value}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )}

      <div class="h-full ">
        <div className='bg-white h-full  border border-layout-border shadow-[0px_1px_2px_0px_#0000000D] rounded-lg py-6 px-10'>
          <div className='font-semibold text-3xl'>
            Account
          </div>
          <div className='font-medium text-sm text-color-gray-light pt-1'>Manage your account details from this section</div>
          <div className="form space-y-2  mt-8">
            <div>
              <label for="name" class="block mb-2  text-base font-semibold text-auth-label">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter Name" class="bg-white border focus:outline-none text-color-gray-light  sm:text-sm rounded-lg  block w-full p-3" />
              {errors.name && (
                <p className="text-error-msg font-medium text-sm pt-2 ">
                  {errors.name}
                </p>
              )}
            </div>


            <div>
              <label
                for="email"
                className="block mb-2 text-base font-semibold text-auth-label"
              >
                Email
              </label>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  disabled={true}
                  value={formData.email}
                  onChange={handleChange}
                  className={`${formData.email ? "bg-disable-button" : "bg-white"} border text-color-gray-light sm:text-sm rounded-lg focus:outline-none block w-full p-3 pr-20`} // Added padding-right to make space for Verify text
                  placeholder="Enter Email"
                />
                {!emailVerified ?
                  <span onClick={handleVerifyEmail} className="absolute text-[#444CE7] right-4 top-1/2 transform -translate-y-1/2 text-sm font-medium cursor-pointer">
                    Verify email
                  </span>
                  : <svg className="absolute  right-4 top-1/2 transform -translate-y-1/2 " width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 8.33333L9.16667 11.6667L7.5 10M10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5Z" stroke="#12B76A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                }
              </div>

              {/* Error Message */}
              {errors.email && (
                <p className="text-error-msg font-medium text-sm pt-2">
                  {errors.email}
                </p>
              )}
            </div>




            <div>
              <label for="number" class="block mb-2 text-base font-semibold text-auth-label ">Contact Number</label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                // disabled={true}
                value={formData.mobile}
                onChange={handleChange}
                class="bg-white border text-color-gray-light sm:text-sm rounded-lg focus:outline-none   block w-full p-3 " placeholder="+1 (555) 000-0000" />

              {errors.phoneNumber && (
                <p className="text-error-msg font-medium text-sm pt-2">
                  {errors.phoneNumber}
                </p>
              )}

            </div>

            <div className="flex justify-end pt-3">
              <SettingButton
                text={"Save"}
                loading={isLoading}

                onClick={handleSubmit}
                disabled={!detectChange}
              />
            </div>
          </div>

          <div className="form space-y-2  mt-2">
            <div>
              <label for="name" class="block mb-2  text-base font-semibold text-auth-label">Old Password</label>
              <input
                type="password"
                id="currentpassword"
                name="oldPassword"
                onChange={handlePasswordChange}
                placeholder="Enter old password" class="bg-white border focus:outline-none text-color-gray-light  sm:text-sm rounded-lg  block w-full p-3" />
              {errors.oldPassword && (
                <p className="text-error-msg font-medium text-sm pt-2 ">
                  {errors.oldPassword}
                </p>
              )}
            </div>


            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-base font-semibold text-auth-label"
              >
                New Password
              </label>
              <div className="relative">
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  onChange={handlePasswordChange}
                  className="bg-white border text-color-gray-light sm:text-sm rounded-lg focus:outline-none block w-full p-3 pr-20" // Added padding-right to make space for Verify text
                  placeholder="Enter new password"
                />

              </div>

              {/* Error Message */}
              {errors.newPassword && (
                <p className="text-error-msg font-medium text-sm pt-2">
                  {errors.newPassword}
                </p>
              )}
            </div>


            <div className="flex justify-end pt-3">
              <SettingButton
                text={"Save"}
                loading={isLoading}


                onClick={handlePasswordSubmit} />
            </div>
          </div>


        </div>




      </div>
    </>
  );
};

export default Account;
