





import axios from "axios";
import React, { useState, useEffect } from "react";
import { baseUrl } from "../../config";
import Button from "../Button/Button";

const Popup_Card = ({ onClose, websiteId, footprintUrl, formData, urlToSend }) => {
  const [websiteUrl, setWebsiteUrl] = useState();
  const [checked, setChecked] = useState(false);
  const [websiteData, setWebsiteData] = useState()
  const websiteDataId = formData?.websiteId || websiteId;
  console.log("data for popUp", websiteDataId, urlToSend);
  const token = localStorage.getItem("Token")
  const handleChange = async (event) => {
    const isChecked = event.target.checked;
    console.log("mai check hu", isChecked)
    setChecked(isChecked);  // Update checkbox state

    // Call the function to update showPopUp in the backend
    await offPopUp(isChecked);
  };

  const offPopUp = async (isChecked) => {
    try {
      const response = await axios.put(`${baseUrl}/websites/closePopup/${websiteDataId}`, {
        showPopUp: isChecked
      });
      console.log(response.data, "Checkbox updated");
    } catch (error) {
      console.error("Error updating popup status:", error);
    }
  };


  const fetchWebsiteByUserId = async () => {
    const userId = localStorage.getItem("userId");

    const storedWebsiteId = localStorage.getItem("latestWebsiteId");
    try {
      if (storedWebsiteId) {
        const response = await axios.get(`${baseUrl}/websites/${storedWebsiteId}`,

          {
            headers: { authorization: `Bearer ${token}` },
          }

        );
        console.log("response from website from popUp Card----------------------------------------", response.data);

        setWebsiteUrl(response.data?.url);
      }
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  const fetchWebsiteByWebsiteId = async () => {
    try {

      const response = await axios.get(`${baseUrl}/websites/${websiteDataId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        });
      console.log("response from website in popUp card", response?.data);
      setWebsiteData(response?.data)


    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };
  useEffect(() => {
    fetchWebsiteByUserId();
    fetchWebsiteByWebsiteId()
  }, [websiteId, formData]);

  const createFootprint = async () => {


    offPopUp();
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("Token");
    localStorage.removeItem('latestWebsiteId');


    console.log("issue ye hai", urlToSend);




    // Send the message with default values or values from formData and urlToSend if they exist
    window.postMessage(
      {
        type: "START_TRACKING",
        url: (formData && formData.footprintUrl) || footprintUrl,
        userId: userId,
        websiteId: (formData && formData.websiteId) || websiteId,
        footprintName: (formData && formData.footprintName) || "Test Tube",
        tags: formData?.tags,  // Default to an empty array if tags are not provided
        websiteUrl: urlToSend || websiteUrl,
        tagsArray: formData?.tagsArray,
        token: token,
        thirdPartyUrls: websiteData?.thirdPartyUrl
      },
      "*"
    );



    console.log("Start tracking message sent to extension");
  };

  return (
    <div className="container ">
      <div className="wrapper  bg-white mx-auto p-6 w-[25vw]  border rounded-xl">
        <div className="outer-section flex flex-col gap-6">
          <div className="top-section flex justify-between items-center">
            <div className="content text-color-gray-light  font-semibold text-2xl ">
              <h3> Get ready </h3>
            </div>

            <div className="cross-icon">


              <svg width="12" height="13" onClick={onClose}
                className="cursor-pointer mr-2" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 11.5L6.00001 6.50001M6.00001 6.50001L1 1.5M6.00001 6.50001L11 1.5M6.00001 6.50001L1 11.5" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

            </div>
          </div>

          <div className="content-section text-sm text-light-black ">
            <p>
              You will now be taken to a new tab to create a foot print.
              These will be the steps the system will continue to run.
            </p>

          </div>

          <div className="checkbox-section  flex items-center">
            <input type="checkbox" id="doNotShow" className="cursor-pointer mr-2 border border-black" onChange={handleChange} />
            <label
              htmlFor="doNotShow"
              className="text-light-black text-sm"
            >
              Do not show this again
            </label>
          </div>




          <div className="bottom-section">
            <Button
              id="button_start_first_test"
              className="button w-full rounded-[4px] p-2 bg-button-bg hover:bg-button-hover-color text-white"
              onClick={createFootprint}
              text="Create Test" >
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup_Card;