import React, { useState, useEffect } from "react";
import Test_Schedule_Card from "../Card/Test_Schedule_Card";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import Test_Home_Card from "../Card/Test_Home_Card";
import axios from 'axios';
import Test_Schedule_Info from "./Test_Schedule_Info";

const Test_Info_Details_Card = ({hide ,anonymousData}) => {
  const [topSection, setTopSection] = useState(false);
  // const { footprintId, websiteId } = useParams();
  const { footprintId: paramFootprintId, websiteId: paramWebsiteId } = useParams();

  const footprintId = paramFootprintId;
  const websiteId = paramWebsiteId ;

  const token = localStorage.getItem("Token")





  const isReadOnly = false;
  const status = "active";


  const [isFilled, setIsFilled] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showScheduleModel, setShowScheduleModel] = useState(false);
  const [footPrint, setFootprint] = useState();
  const navigate = useNavigate();

  window.intercomSettings = {
    app_id: "x5w0eskb",
    custom_launcher_selector: "#help",
  };
  const [formData, setFormData] = useState({
    footprintName: "",
    tags: "",
    tagsArray: [],
  });

  const toggleIcon = () => {
    setIsFilled(!isFilled);
  };



  const handleSaveSchedule = () => {
    const data = { websiteId: websiteId };
    navigate("/footprint-status-list", { state: data });
  };
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const fetchFootprintById = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/footprint/foot/${footprintId}`,

        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      console.log("response from footprint by Id", response.data);

      setFootprint(response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
  };

  useEffect(() => {
    if (footprintId) {
      fetchFootprintById();
    }
  }, [footprintId]);

  useEffect(() => {
    if (footPrint) {
      let Tags = footPrint?.tags;
      let footprintTags = Tags?.join(", ");
      setFormData({
        footprintName: footPrint?.name || "",
        tags: "",
        tagsArray: footPrint?.tagsArray,
      });
    }
  }, [footprintId, footPrint]);

  return (
    <>
      <div className="header-section">

        <div className="info border border-layout-border px-2 rounded-lg bg-layout shadow-[0px_1px_2px_0px_#0000000D]">


          <div className="setup-guide mt-4">
            <div className="inner-setup-guide text-color-gray-light">

              <Test_Schedule_Info
                status={status}
                footPrint={footPrint}
                websiteId={websiteId}
                isReadOnly={true}
                isEditable={true}
                formData={formData}
                setFormData={setFormData}
                hide={hide}
                anonymousData={anonymousData}
              />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test_Info_Details_Card;
