import React, { useState, useEffect } from "react";
import Test_Schedule_Card from "../Card/Test_Schedule_Card";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../config";
import Test_Home_Card from "../Card/Test_Home_Card";
import axios from 'axios';
import Summary_Card from "../Card/Summary_Card";
import DonutChart from "../Chart/DonutChart";
import Empty_Screen_Card from "../Card/Empty_Screen_Card";
import emptyChart from "../Images/empty-chart.png"

const Test_Figures = ({ figures }) => {
  const { websiteId } = useParams();
  const { totalActiveCount, totalInactiveCount, totalCompleteCount, totalPausedCount } = figures;
  const totalCount = totalActiveCount + totalInactiveCount + totalCompleteCount + totalPausedCount;


  console.log(figures, "tyr")
  const webisteId = localStorage.getItem("selectedWebsiteId");
  const totalTest = figures?.totalActiveCount + figures?.totalPausedCount + figures?.totalInactiveCount + figures?.totalCompleteCount








  return (
    <>
      <div className="header-section">

        <div className="info border border-layout-border p-6 rounded-lg bg-layout shadow-[0px_1px_2px_0px_#0000000D] flex flex-col gap-6">


          {totalTest >0 &&
            <div className="setup-guide">
              <div className="inner-setup-guide text-color-gray-light flex justify-between items-center gap-6">
                <Summary_Card title="Total Test" value={totalCount} content="Total number of generated tests that are in your website" icon={false} payload={{ to: `/${webisteId}/test-list`, filter: { status: '' } }}  dashboard={true}
                />
                <Summary_Card title="Total Active Test" value={totalActiveCount} content="Total number of active tests that are in your website" icon={false} payload={{ to: `/${webisteId}/test-list`, filter: { status: 'active' } }}   dashboard={true} />
                <Summary_Card title="Total Draft Test" value={totalInactiveCount} content="Total number of draft tests that are in your website" icon={false} payload={{ to: `/${webisteId}/test-list`, filter: { status: 'inactive' } }} dashboard={true} />
                <Summary_Card title="Total Finish Test" value={totalCompleteCount} content="Total number of  completed tests that are in your website" icon={false} payload={{ to: `/${webisteId}/test-list`, filter: { status: 'complete' } }} dashboard={true} />

              </div>
            </div>}

          <div className="chart-section">
            <div className="inner-chart-section text-color-gray-light  ">


              {
                totalCount > 0 ?
                  (<div className="chart-container">
                    <DonutChart figures={figures} />
                  </div>) :

                  <div className="empty-screen py-[60px] bg-white rounded-lg">
                    <Empty_Screen_Card
                      image={emptyChart}
                      to={`/${websiteId}/test`}
                      buttonName="Create Test"
                      mainContent="No Tests Created"
                      subContent={
                        <>
                          You need to create tests to <br />
                          monitor on dashboard.
                        </>
                      }
                    />
                  </div>
              }







            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Test_Figures;
