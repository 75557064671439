import React, { useEffect, useState } from "react";
import FailToast from "../Toast/FailToast";
import { baseUrl } from "../../config";
import axios from "axios"
import SuccessToast from "../Toast/SuccessToast";
import usePushNotifications from "../../hooks/usePushNotifications";
import Alert_Card from "../Card/Alert_Card";
import SettingButton from "../Button/SettingButton";


const Notification = () => {
  const [fail, setFail] = useState([]);
  const [billing, setBilling] = useState([]);
  const [activeFail, setActiveFail] = useState(false);
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [msg, setMsg] = useState("");
  const [isSubscribe, setIsSubscribe] = useState()
  const [isEmailEnabled, setIsEmailEnabled] = useState(true)
  const { initializePushNotifications } = usePushNotifications();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [showPop, setShowPop] = useState(false); // State to control Alert_Card visibility
  const [selectedOptionId, setSelectedOptionId] = useState(null); // To track which toggle was clicked
  const [selectedEvent, setSelectedEvent] = useState(null); // To store the event for later use
  const [popText, setPopText] = useState(null)
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([])
  const [isChecked, setIsChecked] = useState(false);
  const [value, setValue] = useState("");
  const [alternateEmails, setAlternateEmails] = useState([])
  const [detectChange, setDetectChange] = useState(false)


  const [inputFields, setInputFields] = useState([""]);


  const [billingToggleStates, setBillingToggleStates] = useState({
    billing_email: true,
    billing_sms: false,
  });
  const [failToggleStates, setFailToggleStates] = useState({
    fail_notification_email: true,
    fail_notification_push: true
  });
  const notificationOptions = [
    { label: "Email", id: "fail_notification_email" },
    { label: "Push ", id: "fail_notification_push" },
  ];


  const handlePopup = () => {
    setShowPop(true);
  };




  const [errors, setErrors] = useState([]); // State to track validation errors

  // const validateEmail = (email) => {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  


  const handleSave = async (emails, dropBox = false, index) => {
    console.log("Added EMails------------------", alternateEmails)
    const userId = localStorage.getItem("userId");

    alternateEmails.splice(index, 1);

    console.log("alternateEMAIL",alternateEmails)



    const updatedEmail = dropBox ? alternateEmails : emails;
    console.log(updatedEmail, "check")

    setLoading(dropBox? false:true)
    // if (emails.length > 0) {
      try {
        const response = await axios.put(
          `${baseUrl}/user/alternate-emails`,
          { alternateEmails: updatedEmail, userId },

        );
        console.log("response", response.data);
        setLoading(false)
        setActiveSuccess(true)
        setValue("Emails updated successfully")
        // window.location.reload()


      } catch (error) {
        setLoading(false)
      }
    // }
    // else {
    //   console.log("Alternate email must not be empty")
    //   return;
    // }
  }

  const handleInputChange2 = (index, event) => {
    const values = [...inputFields];
    const newValue = event.target.value;

    values[index] = newValue;
    setDetectChange(true)
    setInputFields(values);


    const newErrors = [...errors];
    if (validateEmail(newValue) || newValue.trim() === "") {
      newErrors[index] = null;
      if (validateEmail(newValue)) {
        const updatedEmails = [...emails];
        updatedEmails[index] = newValue;
        setEmails(updatedEmails);
      }
    } else {
      newErrors[index] = "Invalid email address";
    }
    setErrors(newErrors);
  };

  const handleAddField = () => {


    const filteredFields = inputFields.filter((field) => field.trim() !== "");
    setInputFields([...filteredFields, ""]);
    setErrors([...errors, null]);
  };

  const handleRemoveField = async (index) => {
    const values = [...inputFields];
    const newErrors = [...errors];
    const updatedEmails = [...emails];

    values.splice(index, 1);
    newErrors.splice(index, 1);
    updatedEmails.splice(index, 1);

    setInputFields(values);
    setErrors(newErrors);
    setEmails(updatedEmails);

    await handleSave(updatedEmails, true, index)
  };



  const fetchUserSubscription = async () => {
    try {
      const userId = localStorage.getItem("userId")
      const response = await axios.get(`${baseUrl}/pushNotification/subscription-status/${userId}`);
      console.log("response from pushNotification", response.data)
      setIsSubscribe(response?.data?.isSubscribed)



    } catch (error) {
      console.log('Error fetching PushNotification:', error.response);

    }
    return;
  };


  const deleteUserSubscription = async () => {
    const userId = localStorage.getItem("userId")
    try {
      const response = await axios.delete(
        `${baseUrl}/pushNotification/delete-subscription/${userId}`
      );
      setIsSubscribe(false)
      console.log("response from deleteSubscription", response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
    return;

  }

  const fetchUserEmailEnabled = async () => {
    try {
      const userId = localStorage.getItem("userId")
      const response = await axios.get(`${baseUrl}/onboard/${userId}`);
      console.log("response from Onboard data from notification", response?.data?.onboardData[0]?.user?.alternateEmails);
      setIsEmailEnabled(response?.data?.onboardData[0]?.user?.isEmailEnabled)
      setAlternateEmails(response?.data?.onboardData[0]?.user?.alternateEmails)

    } catch (error) {
      console.log("Error in fetching data", error.response);

    }
  };

  const updateUserEmailEnabled = async (isEmailEnabled) => {
    const userId = localStorage.getItem("userId")
    try {
      const response = await axios.patch(
        `${baseUrl}/user/${userId}/notification`, { isEmailEnabled }
      );
      console.log("response from updateUserEmailEnabled", response.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    }
    return;

  }

  const handleChangeNotification = async (event, id) => {

    if (!event.target.checked && !selectedEvent && !selectedOptionId) {
      // If toggling off, show Alert_Card

      setSelectedOptionId(id);
      setSelectedEvent(event);
      setPopText(id === "fail_notification_email" ? "email" : "push")
      setShowPop(true);
      return; // Exit without updating state immediately
    }

    // Proceed with toggling on or confirmed action
    if (!isSubscribe && id === "fail_notification_push") {
      await initializePushNotifications();
      await fetchUserSubscription();
    }

    if (isSubscribe && id === "fail_notification_push") {
      await deleteUserSubscription();
    }

    if (id === "fail_notification_email") {
      await updateUserEmailEnabled(!isEmailEnabled);
      await fetchUserEmailEnabled();
    }

    // Update toggle state
    setFailToggleStates((prevState) => ({
      ...prevState,
      [id]: event.target.checked,
    }));
  };


  const handleConfirmPopup = async () => {
    if (selectedOptionId && selectedEvent) {
      console.log("calling handleConfirm", selectedEvent.target.checked, selectedOptionId)
      await handleChangeNotification(selectedEvent, selectedOptionId); // Proceed with action
    }
    handleClosePopup(); // Close popup after confirmation
  };

  const handleClosePopup = () => {
    setShowPop(false); // Close popup without making changes
    setSelectedOptionId(null); // Clear stored option ID
    setSelectedEvent(null); // Clear stored event
  };



  const handleChangeBilling = (event, id) => {
    setBillingToggleStates((prevState) => ({
      ...prevState,
      [id]: event.target.checked,
    }));
  };



  const handleFilterTrueValues = () => {

    if (
      Array.isArray(billingToggleStates) ||
      typeof billingToggleStates === "object"
    ) {
      const trueBillingToggles = Object.entries(billingToggleStates)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      setBilling(trueBillingToggles);
    }

    if (
      Array.isArray(failToggleStates) ||
      typeof failToggleStates === "object"
    ) {
      const trueFailToggles = Object.entries(failToggleStates)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      setFail(trueFailToggles);

    }
  };

  const handleNotification = () => {
    console.log("fail from handleNotification", fail);
    if (fail.length == 0) {
      setActiveFail(true);
      setMsg(
        "Oops! You need to have at least 1 notification enabled for Foot Print failure notifications."
      );
    } else {
      setActiveSuccess(true);
      setMsg("Awesome! Notifications settings updated successfully");
    }
  };
  const handleBilling = () => {
    console.log("fail from handleBilling", billing);

    if (billing.length == 0) {
      setActiveFail(true);
      setMsg(
        " Oops!You need to have at least 1 notification enabled for billing reminders."
      );
    } else {
      setActiveSuccess(true);
      setMsg("Awesome! Notifications settings updated successfully");
    }
  };


  const handleCheckboxClick = () => {
    setIsChecked(!isChecked); // Update the state with the checkbox value
  };


  useEffect(() => {
    handleFilterTrueValues();
  }, [failToggleStates, billingToggleStates]);


  useEffect(() => {
    fetchUserSubscription()
    fetchUserEmailEnabled()
  }, [])




  useEffect(() => {
    setFailToggleStates(prevState => ({
      ...prevState,
      fail_notification_push: isSubscribe,
      fail_notification_email: isEmailEnabled
    }));


  }, [isSubscribe, isEmailEnabled])




  useEffect(() => {
    if (alternateEmails.length > 0) {
      setInputFields(alternateEmails?.length > 0 ? [...alternateEmails] : [""]);
      setEmails(alternateEmails?.length > 0 ? [...alternateEmails] : [])
      setIsChecked(true)

    }

  }, [alternateEmails])


  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
      }, 2000);
    }
  }, [activeFail, activeSuccess]);

  console.log("errors", errors)

  return (


    <div class="h-full ">

      {activeFail && (
        <FailToast
          errorValue={value}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}
      {activeSuccess && (
        <SuccessToast
          successValue={value}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )}
      {showPop && (
        <>
          <div className="fixed inset-0 bg-black opacity-50 z-40"></div>{" "}
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <Alert_Card
              // mainContent={`Are you sure you want to switching off ${popText} notifications?`}
              mainContent={
                <>
                  Are you sure you want to switching off <br />
                  {popText} notifications?
                </>
              }
              onClose={handleClosePopup}
              handleNotification={handleConfirmPopup}
              type={"notification"}
              // smallContent={`You are about to switch off ${popText} notifications. We will not send you any ${popText} if your tests fail.`}
              smallContent={<>
                You are about to switch off {popText} notifications. We will not send<br />
                you any {popText} if your tests fail.
              </>}
            />
          </div>
        </>
      )}
      <div className='bg-white h-full  border border-layout-border shadow-[0px_1px_2px_0px_#0000000D] rounded-lg py-6 px-10'>
        <div className='font-semibold text-3xl'>
          Notifications
        </div>
        <div className='font-medium text-sm text-color-gray-light pt-1'>
          Manage your notification detail from this section
        </div>
        <div className="mt-8">
          {notificationOptions.map((option) => (
            <>
              <div
                key={option.id}
                className="flex items-center justify-between mt-4 p-3 rounded-md border border-link-border"
              >
                <div className="flex flex-col space-y-[6px]">
                  <span className="text-color-gray-light font-semibold text-base">
                    {option.label} Notifications
                  </span>
                  <span className="font-normal text-xs text-input-remark">
                    We’ll send you {option.label.toLowerCase()} notifications whenever your tests fail.
                  </span>
                </div>

                <div className="flex justify-center items-center space-x-2"
                  onClick={(event) =>
                    handleChangeNotification(
                      { target: { checked: !failToggleStates[option.id] } },
                      option.id
                    )
                  }


                >
                  {failToggleStates[option.id] ?


                    <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer">
                      <g clip-path="url(#clip0_2720_52341)">
                        <rect width="36" height="20" rx="10" fill="#875BF7" />
                        <g filter="url(#filter0_dd_2720_52341)">
                          <circle cx="26" cy="10" r="8" fill="white" />
                        </g>
                      </g>
                      <defs>
                        <filter id="filter0_dd_2720_52341" x="12" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_2720_52341" />
                          <feOffset dy="2" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.06 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2720_52341" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_2720_52341" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="4" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.1 0" />
                          <feBlend mode="normal" in2="effect1_dropShadow_2720_52341" result="effect2_dropShadow_2720_52341" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2720_52341" result="shape" />
                        </filter>
                        <clipPath id="clip0_2720_52341">
                          <rect width="36" height="20" rx="10" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    :
                    <svg width="36" height="20" viewBox="0 0 36 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer">
                      <g clip-path="url(#clip0_2720_52344)">
                        <rect width="36" height="20" rx="10" fill="#E5E7EB" />
                        <g filter="url(#filter0_dd_2720_52344)">
                          <circle cx="10" cy="10" r="8" fill="white" />
                        </g>
                      </g>
                      <defs>
                        <filter id="filter0_dd_2720_52344" x="-4" y="0" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_2720_52344" />
                          <feOffset dy="2" />
                          <feGaussianBlur stdDeviation="2" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.06 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2720_52344" />
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_2720_52344" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="4" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0 0.0901961 0 0 0 0.1 0" />
                          <feBlend mode="normal" in2="effect1_dropShadow_2720_52344" result="effect2_dropShadow_2720_52344" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2720_52344" result="shape" />
                        </filter>
                        <clipPath id="clip0_2720_52344">
                          <rect width="36" height="20" rx="10" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                  }
                </div>


              </div>

              {option.id === "fail_notification_email" &&
                <>
                  <div className="flex   items-center space-x-2 mt-2">

                    <div className="flex items-center">

                      {isChecked ? (
                        <svg onClick={handleCheckboxClick} className="cursor-pointer"
                          width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#111322" />
                          <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="black" />
                          <path d="M12 5L6.5 10.5L4 8" stroke="#F9F9FB" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                      ) : (
                        <svg onClick={handleCheckboxClick} className="cursor-pointer" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="white" />
                          <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#D0D5DD" />
                        </svg>

                      )}
                    </div>

                    <div className="text-sm font-medium text-color-gray-light">
                      Send test fail email notification to other members of organisation
                    </div>
                  </div>

                  {isChecked &&

                    <div className="mt-2 ">
                      {inputFields.map((value, index) => (
                        <>
                          <div key={index} className="flex items-center space-x-2 pb-1.5 ">

                            <input
                              type="text"
                              value={value}
                              onChange={(event) => handleInputChange2(index, event)}
                              placeholder={` Email ${index + 1}`}

                              className="bg-white border text-color-gray-light sm:text-sm rounded-lg focus:outline-none   block w-full p-3 " />


                            {inputFields.length > 1 &&
                              <button
                                type="button"
                                onClick={() => handleRemoveField(index)}
                                disabled={inputFields.length === 1}
                                className="w-6 h-6  text-white flex items-center cursor-pointer justify-center  focus:outline-none focus:ring-2 focus:ring-[#E4E4E4]"
                              >
                                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M3 4V15.8C3 16.9201 3 17.4798 3.21799 17.9076C3.40973 18.2839 3.71547 18.5905 4.0918 18.7822C4.5192 19 5.07899 19 6.19691 19H11.8031C12.921 19 13.48 19 13.9074 18.7822C14.2837 18.5905 14.5905 18.2839 14.7822 17.9076C15 17.4802 15 16.921 15 15.8031V4M3 4H5M3 4H1M5 4H13M5 4C5 3.06812 5 2.60241 5.15224 2.23486C5.35523 1.74481 5.74432 1.35523 6.23438 1.15224C6.60192 1 7.06812 1 8 1H10C10.9319 1 11.3978 1 11.7654 1.15224C12.2554 1.35523 12.6447 1.74481 12.8477 2.23486C12.9999 2.6024 13 3.06812 13 4M13 4H15M15 4H17" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                              </button>
                            }


                          </div>

                          {errors[index] && (
                            <p className="text-red-500 text-sm py-1 ">{errors[index]}</p>
                          )}
                        </>

                      ))}


                      <div className='flex flex-end mt-2 '>


                        <button
                          onClick={handleAddField}
                          className={` inline-flex items-center justify-center py-2 px-3 text-sm font-semibold focus:outline-none rounded border bg-[#EAECF0]
                             disabled:opacity-50 disabled:bg-secondary-button  disabled:border-secondary-border disabled:cursor-not-allowed
                            `}


                          disabled={errors.some((error) => error !== null && error !== undefined)}

                        >
                          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                            <path d="M1.5 5H5M5 5H8.5M5 5V8.5M5 5V1.5" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>  Add Email

                        </button>

                      </div>

                      <div className='flex justify-end  space-x-2'>
                        <SettingButton text={"Save"}
                          disabled={errors.some((error) => error !== null && error !== undefined) || !detectChange}
                          onClick={() => handleSave(emails)}
                          loading={loading}
                        />

                      </div>


                    </div>


                  }


                </>
              }

            </>

          ))}


        </div>




      </div>




    </div>
  );
};

export default Notification;


