import React, { useEffect, useState } from 'react'
import Test_Schedule from '../components/Footprint/Test_Schedule'
import ExtensionAlert from '../components/Card/ExtensionAlert';

const TestDetailsPage = () => {
    const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
    const [showExtensionConfirm, setShowExtensionConfirm] = useState(false);
  

  useEffect(() => {
      const handleLoad = () => {
        console.log("Page fully loaded");
  
        const hasShownConfirmation = localStorage.getItem("hasShownConfirmation");
        const messageListener = (event) => {
          if (event.source !== window) return;
          if (event.data.type === "EXTENSION_INSTALLED") {
            console.log("Extension is installed:", event.data.data);
  
            setIsExtensionInstalled(true);
  
            if (!hasShownConfirmation) {
              setShowExtensionConfirm(true);
            }
          }
        };
  
        // Add event listener for messages
        window.addEventListener("message", messageListener);
  
        // Send a message to check if the extension is installed
        console.log("Sending CHECK_EXTENSION message");
        window.postMessage({ type: "CHECK_EXTENSION" }, "*");
  
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener("message", messageListener);
        };
      };
  
      // Check if document is already loaded
      if (document.readyState === 'complete') {
        handleLoad();
      } else {
        window.addEventListener('load', handleLoad);
  
        // Clean up the load event listener
        return () => {
          window.removeEventListener('load', handleLoad);
        };
      }
    }, []);
  return (
    <>
      <div className="guides">
      {!isExtensionInstalled && <ExtensionAlert />}

        <div className="inner-guides">
          <Test_Schedule isExtensionInstalled={isExtensionInstalled}/>
        </div>
      </div>
    </>
  )
}

export default TestDetailsPage