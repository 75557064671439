// import React, { useEffect, useState } from "react";

// import { useParams } from "react-router-dom";
// import Test_Landing_Card from "../Card/Test_Landing_Card";
// import Button from "../Button/Button";
// import { baseUrl } from "../../config";
// import TestListPage from "../../pages/TestListPage";
// import axios from 'axios';
// import ExtensionAlert from "../Card/ExtensionAlert";
// const Test_Landing = () => {
//     const { websiteId } = useParams();
//     const [website, setWebsite] = useState();
//     const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
//     const [showExtensionConfirm, setShowExtensionConfirm] = useState(false);
//     const fetchWebsiteByWebsiteId = async () => {
//         try {

//             const response = await axios.get(`${baseUrl}/websites/${websiteId}`);
//             console.log("response from website", response?.data);
//             setWebsite(
//                 response?.data
//             );
//         } catch (error) {
//             console.log("Error in fetching data", error.response);
//         }
//     };

//     useEffect(() => {
//         fetchWebsiteByWebsiteId();
//     }, [websiteId])


//   useEffect(() => {
//     const handleLoad = () => {
//       console.log("Page fully loaded");

//       const hasShownConfirmation = localStorage.getItem("hasShownConfirmation");
//       const messageListener = (event) => {
//         if (event.source !== window) return;
//         if (event.data.type === "EXTENSION_INSTALLED") {
//           console.log("Extension is installed:", event.data.data);         

//           setIsExtensionInstalled(true);


//           if (!hasShownConfirmation) {
//             setShowExtensionConfirm(true);
//           }
//         }
//       };

//       // Add event listener for messages
//       window.addEventListener("message", messageListener);

//       // Send a message to check if the extension is installed
//       console.log("Sending CHECK_EXTENSION message");
//       window.postMessage({ type: "CHECK_EXTENSION" }, "*");

//       // Clean up event listener on component unmount
//       return () => {
//         window.removeEventListener("message", messageListener);
//       };
//     };

//     // Check if document is already loaded
//     if (document.readyState === 'complete') {
//       handleLoad();
//     } else {
//       window.addEventListener('load', handleLoad);

//       // Clean up the load event listener
//       return () => {
//         window.removeEventListener('load', handleLoad);
//       };
//     }
//   }, []);


//     return (
//         <>
//             <div className="header-section">
//                 {!isExtensionInstalled && <ExtensionAlert/>}
//                 <div className="info">
//                     <div className="flex flex-col gap-6">
//                         <div className="guide-info text-color-gray-light flex justify-between items-center">
//                             <h2 className='font-bold text-[24px]'>Tests</h2>
//                             <Button
//                                 text="Create New Test"
//                                 to={`/${websiteId}/test`}
//                                 disabled={!isExtensionInstalled}

//                             />

//                         </div>

//                         {/* mt-5 remove */}
//                         <div className="setup-guide  flex justify-between">
//                             <div className="inner-setup-guide flex flex-col gap-6 text-color-gray-light w-full">
//                                 {website?.footprints?.length > 0 ? <TestListPage /> : <Test_Landing_Card websiteId={websiteId} isExtensionInstalled={isExtensionInstalled} />}

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default Test_Landing;


import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Test_Landing_Card from "../Card/Test_Landing_Card";
import Button from "../Button/Button";
import { baseUrl } from "../../config";
import TestListPage from "../../pages/TestListPage";
import axios from 'axios';
import ExtensionAlert from "../Card/ExtensionAlert";

const Test_Landing = () => {
  const { websiteId } = useParams();
  const [website, setWebsite] = useState();
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);
  const [showExtensionConfirm, setShowExtensionConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const token = localStorage.getItem("Token")

  const fetchWebsiteByWebsiteId = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${baseUrl}/websites/${websiteId}`,

        {
          headers: { authorization: `Bearer ${token}` },
        }

      );
      console.log("response from website", response?.data);
      setWebsite(response?.data);
    } catch (error) {
      console.log("Error in fetching data", error.response);
    } finally {
      setIsLoading(false); // Stop loading once data is fetched
    }
  };

  useEffect(() => {
    fetchWebsiteByWebsiteId();
  }, [websiteId]);

  useEffect(() => {
    const handleLoad = () => {
      console.log("Page fully loaded");

      const hasShownConfirmation = localStorage.getItem("hasShownConfirmation");
      const messageListener = (event) => {
        if (event.source !== window) return;
        if (event.data.type === "EXTENSION_INSTALLED") {
          console.log("Extension is installed:", event.data.data);

          setIsExtensionInstalled(true);

          if (!hasShownConfirmation) {
            setShowExtensionConfirm(true);
          }
        }
      };

      // Add event listener for messages
      window.addEventListener("message", messageListener);

      // Send a message to check if the extension is installed
      console.log("Sending CHECK_EXTENSION message");
      window.postMessage({ type: "CHECK_EXTENSION" }, "*");

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener("message", messageListener);
      };
    };

    // Check if document is already loaded
    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('load', handleLoad);

      // Clean up the load event listener
      return () => {
        window.removeEventListener('load', handleLoad);
      };
    }
  }, []);

  const svg = <svg
    className="animate-spin h-12 w-12 mr-2 text-black"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.042.784 3.903 2.071 5.291l1.929-1.929z"
    ></path>
  </svg>
  return (
    <>
      <div className="header-section">
        {!isExtensionInstalled && <ExtensionAlert />}
        <div className="info">
          <div className="flex flex-col gap-6">
            <div className="guide-info text-color-gray-light flex justify-between items-center">
              <h2 className='font-bold text-[24px]'>Tests</h2>
              <Button
                text="Create New Test"
                to={`/${websiteId}/test`}
                disabled={!isExtensionInstalled}
              />
            </div>

            {/* mt-5 remove */}
            <div className="setup-guide flex justify-between">
              <div className="inner-setup-guide flex flex-col gap-6 text-color-gray-light w-full">
                {isLoading ? (
                  // Skeleton loader during loading state
                  // <Skeleton height={150} count={1} />
                  // <div className=" relative flex justify-center items-center h-full top-32  ">
                  //div
                  <Test_Landing_Card svg={svg} />

                ) : (
                  // Render components based on condition after loading
                  website?.footprints?.length > 0 ? (
                    <TestListPage />
                  ) : (
                    <Test_Landing_Card
                      websiteId={websiteId}
                      isExtensionInstalled={isExtensionInstalled}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Test_Landing;
