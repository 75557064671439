import React, { useEffect, useState } from "react";
import {decryptUserName} from "../../utils/encrypt"

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { baseUrl } from "../../config";
import axios from 'axios';

import Drop_Down_Card from "../Card/Drop_Down_Card";


import Share_Video_Summary from "../Footprint/Share_Video_Summary";

const AnonymousTestInfo = () => {
    // const [user, setUser] = useState()
    const [decryptedUserName,setDecryptedUserName] = useState('');
    const { websiteId, footprintId, videoId } = useParams();
    const [searchParams] = useSearchParams();
    const user = searchParams.get('user');
    console.log("username", user)

    const dashboardWebsiteId = localStorage.getItem('selectedWebsiteId');
    const navigate = useNavigate();

    const [data, setData] = useState();
    const [copy, setCopy] = useState("Copy Test Link");
    const username = localStorage.getItem("username");


  

    const handleDecrypt = () => {
        try {
          if (user) {
            const decrypted = decryptUserName(user);
            setDecryptedUserName(decrypted);
            console.log('Decrypted UserName:', decrypted);
          }
        } catch (error) {
          console.error('Decryption failed:', error.message);
        }
      };
      

    //   useEffect(()=>{
    //       console.log("testing" , decryptUserName("5JQ5g+ShIsIvIuouT8PawA=="))
    //   },[])


    // const copyTestLink = () => {
    //     const url = window.location.href; // Get the full URL of the current page
    //     navigator.clipboard.writeText(url)
    //         .then(() => {
    //             console.log("URL copied to clipboard:", url);
    //             setCopy("Copied!!"); // Show "Copied!" message
    //             setTimeout(() => {
    //                 setCopy("Copy Test Link"); // Hide after 2 seconds
    //             }, 1500);
    //         })
    //         .catch(err => {
    //             console.error("Failed to copy URL:", err);
    //         });
    // };

    const copyTestLink = () => {
        let url = window.location.href; // Get the full URL of the current page
    
        // Remove the 'user' query parameter
        const cleanedUrl = url.split("?")[0];
    
        navigator.clipboard.writeText(cleanedUrl)
            .then(() => {
                console.log("URL copied to clipboard:", cleanedUrl);
                setCopy("Copied!!"); // Show "Copied!" message
                setTimeout(() => {
                    setCopy("Copy Test Link"); // Hide after 2 seconds
                }, 1500);
            })
            .catch(err => {
                console.error("Failed to copy URL:", err);
            });
    };
    

    const [openDropdown, setOpenDropdown] = useState("Status"); // Track the currently open dropdown

    const handleToggle = (title) => {
        setOpenDropdown((prev) => (prev === title ? null : title));
    };
    const goBackToList = () => {
        navigate(`/${dashboardWebsiteId}/test-list`);
    }



    // const fetchUserById = async () => {
    //     if (userId) {
    //         try {
    //             const response = await axios.get(`${baseUrl}/user/${userId}`);
    //             console.log("response from userDetails", response.data);
    //             setUser(response.data);
    //         } catch (error) {
    //             console.log("Error in fetching data", error.response);
    //         }
    //     }

    // };









    const formatDateTime = (isoString) => {
        try {
            const date = new Date(isoString);

            if (isNaN(date.getTime())) {
                throw new Error(`Invalid ISO string: ${isoString}`);
            }

            // Correct month option
            const options = { month: "short", day: "numeric" }; // Use "short", "long", "numeric", or "2-digit" for `month`
            const formattedDate = date.toLocaleDateString("en-US", options);

            const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
            const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

            const dayName = date.toLocaleDateString("en-US", { weekday: "long" });

            return {
                date: `${formattedDate}, at ${formattedTime}`,
                dayName,
            };
        } catch (error) {
            console.error("Error formatting date:", error.message);
            return {
                date: "Invalid date",
                dayName: "Invalid day",
            };
        }
    };



    const { date, dayname } = formatDateTime(data?.video?.createdAt);
    console.log("date", date)


    useEffect(() => {
        const fetchAnonymousData = async () => {
            const response = await axios.get(`${baseUrl}/share/website/${websiteId}/footprint/${footprintId}/video/${videoId}`)
            console.log('diya ', response.data)
            setData(response.data);

        }


        fetchAnonymousData();
        handleDecrypt()
    }, [user])



    return (
        <>

            
            <div className="header-section px-4  md:p-6">  {/** p-4 remove */}
                <div className="info">
                    <div className="flex flex-col gap-6">
                        <div className="hidden md:flex guide-info text-color-gray-light gap-4 mx-2 items-center">
                            <svg
                                onClick={goBackToList}
                                className="cursor-pointer w-3 h-3"
                                viewBox="0 0 6 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 9L1 5L5 1" stroke="#111322" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <h2
                                className='font-semibold cursor-pointer text-lg sm:text-xl'
                                onClick={goBackToList}>
                                Back to Dashboard
                            </h2>
                        </div>

                        <div className="top-section  flex  flex-wrap items-start  flex-col md:flex-row justify-between md:items-center gap-4">
                            <div className="timer-section flex flex-col gap-4">
                                <div className="test-heading-container flex-wrap gap-4 flex items-center">
                                    <div className="title text-color-gray-light text-lg sm:text-2xl font-semibold flex items-center gap-2">
                                        {data?.name}
                                        <span className="text-sm text-color-gray-light relative top-[2px]">(Run# 1)</span>
                                    </div>
                                    <div className="flex items-center">
                                        <div className={`flex items-center px-3 py-1 rounded-full text-xs font-semibold w-max border 
                                        ${data?.video?.status === "success" ? "bg-green text-green border-green" : "bg-error-video text-error border-error"}`}>
                                            <span className={data?.video?.status === "success" ? "text-green-600" : "text-red-600"}>
                                                {data?.video?.status === "success" ? "Pass" : "Fail"}
                                            </span>
                                        </div>
                                    </div>
                                    {decryptedUserName &&
                                        <div className="sharer-name font-medium text-shared-gray text-sm">
                                            Shared by {decryptedUserName}
                                        </div>
                                    }
                                </div>
                                <div className="time-section text-sm sm:text-base">
                                    {date}
                                </div>
                            </div>

                            <div className="copy-container flex items-center gap-3 cursor-pointer" onClick={copyTestLink}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7 7V4.2C7 3.08 7 2.52 7.218 2.092C7.41 1.715 7.715 1.41 8.092 1.218C8.52 1 9.08 1 10.2 1H15.8C16.92 1 17.48 1 17.908 1.218C18.284 1.41 18.59 1.715 18.782 2.092C19 2.52 19 3.08 19 4.2V9.8C19 10.92 19 11.48 18.782 11.908C18.59 12.284 18.284 12.59 17.908 12.782C17.48 13 16.921 13 15.803 13H13M7 7H4.2C3.08 7 2.52 7 2.092 7.218C1.715 7.41 1.41 7.715 1.218 8.092C1 8.52 1 9.08 1 10.2V15.8C1 16.92 1 17.48 1.218 17.908C1.41 18.284 1.715 18.59 2.092 18.782C2.519 19 3.079 19 4.197 19H9.804C10.922 19 11.481 19 11.908 18.782C12.284 18.59 12.59 18.284 12.782 17.908C13 17.48 13 16.921 13 15.803V13M7 7H9.8C10.92 7 11.48 7 11.908 7.218C12.284 7.41 12.59 7.715 12.782 8.092C13 8.519 13 9.079 13 10.197V13" stroke="#111322" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span className="text-light-black font-semibold text-sm">{copy}</span>
                            </div>
                        </div>

                        <div className="test-status-section">
                            <div className="drop-down-section flex flex-col gap-6  md:bg-white md:rounded-lg md:border md:border-layout-border md:shadow-md md:p-6">
                                <Share_Video_Summary
                                    videoUrl={data?.video?.url}
                                    logUrl={data?.video?.logUrl}
                                    websiteId={websiteId}
                                    footprintId={footprintId}
                                    videoId={videoId}
                                />
                            </div>
                        </div>

                        <div className="test-details-container">
                            <Drop_Down_Card
                                title="Details"
                                isOpen={openDropdown === "Details"}
                                onToggle={handleToggle}
                                hide={true}
                                anonymousData={data}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AnonymousTestInfo;
