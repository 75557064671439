
// import CryptoJS from 'crypto-js';

// // Secret key for encryption and decryption
// const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

// // Check if SECRET_KEY is defined
// if (!SECRET_KEY) {
//   console.error("SECRET_KEY is not defined. Ensure it's in your .env file and restart the app.");
// }

// // Convert secret key into a valid format
// const KEY = CryptoJS.enc.Utf8.parse(SECRET_KEY); 

// // Function to encrypt data
// export const encryptUserName = (user) => {
//   if (!SECRET_KEY) return null;

//   console.log("Encrypting user:", user ,SECRET_KEY); // Debugging

//   const encrypted = CryptoJS.AES.encrypt(user, KEY, {
//     mode: CryptoJS.mode.ECB,  // Keep ECB if needed, but consider CBC for security
//     padding: CryptoJS.pad.Pkcs7
//   }).toString();

//   console.log("Encrypted String:", encrypted); // Debugging
//   return encrypted;
// };

// // Function to decrypt data
// export const decryptUserName = (encryptedUserName) => {
//   if (!SECRET_KEY) return null;

//   try {
//     console.log("Start decryption" ,SECRET_KEY);

//     const bytes = CryptoJS.AES.decrypt(encryptedUserName, KEY, {
//       mode: CryptoJS.mode.ECB,
//       padding: CryptoJS.pad.Pkcs7
//     });

//     const decrypted = bytes.toString(CryptoJS.enc.Utf8);
//     console.log("Decrypted Value:", decrypted); // Debugging

//     if (!decrypted) {
//       throw new Error("Decryption resulted in an empty string.");
//     }

//     return decrypted;
//   } catch (error) {
//     console.error('Decryption failed:', error.message);
//     return null;
//   }
// };



import CryptoJS from 'crypto-js';

// Ensure KEY & IV are in .env file
const SECRET_KEY = process.env.REACT_APP_KEY_HEX; // Must be 32 bytes
const SECRET_IV = process.env.REACT_APP_IV_HEX;   // Must be 16 bytes



// Parse key and IV correctly
const key = CryptoJS.enc.Hex.parse(SECRET_KEY);
const iv = CryptoJS.enc.Hex.parse(SECRET_IV);

// Encrypt data
export const encryptUserName = (data) => {
  const encrypted = CryptoJS.AES.encrypt(data, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return encrypted.toString(); // Returns Base64
};

// Decrypt data
export const decryptUserName = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return bytes.toString(CryptoJS.enc.Utf8);
};
