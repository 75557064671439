import React from 'react'
import { useNavigate } from 'react-router-dom'

const Summary_Card = ({ title, content, value, icon = false, payload ,dashboard=false , summary=false ,tooltipMessage  }) => {
  const navigate = useNavigate();
  const navigateToList = () => {
    navigate(`${payload.to}`, { state: payload.filter });
  }

  return (
    <>


{/* <div className="summary-card-container flex flex-col gap-4">
  <div className="inner-card-section p-3 rounded-lg flex flex-col text-[16px] border border-layout-border font-semibold text-color-gray-light bg-white min-w-[150px] flex-1 relative"> */}
    <div className="summary-card-container flex flex-col gap-4 w-full max-w-[180px] sm:max-w-none">
  <div className="inner-card-section p-2 sm:p-3 rounded-lg flex flex-col text-sm sm:text-base border border-layout-border font-semibold text-color-gray-light bg-white min-w-[120px] w-full max-w-[180px] flex-1 relative">

    <div className="header-section grid grid-cols-[1fr_auto] items-start">
      <div className="title">{title}</div>

      <div className="flex items-center gap-2 absolute right-3 top-4">
        {icon && (
          <svg onClick={navigateToList} className="cursor-pointer" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 1.16699L7.33333 7.00033L1.5 12.8337" stroke="#111322" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        )}

        {(!dashboard && !summary ) &&  (
          


          <div className="relative flex items-center">
  <div className="group relative">
    <svg
      className="cursor-pointer"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 7.33333V10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14ZM8.0332 5.33333V5.4L7.9668 5.40013V5.33333H8.0332Z"
        stroke="#111322"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>

    <div className="absolute top-full right-0 bg-black text-white text-xs px-2 py-1 rounded opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-200 min-w-[150px]">
      {tooltipMessage}
    </div>
  </div>
</div>


          
        )}

        

      </div>
    </div>

    <div className="mt-auto text-xl font-semibold text-color-gray-light">{value}</div>

    {dashboard && <p className="content font-normal text-xs text-input-remark">{content}</p>}
  </div>
</div>





    </>
  )
}

export default Summary_Card


