import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import FailToast from '../Toast/FailToast';
import SuccessToast from '../Toast/SuccessToast';
import { baseUrl } from '../../config';
import SettingButton from '../Button/SettingButton';


const Organisation = () => {
    // organisation
    const [countries, setCountries] = useState([]); // To store the list of countries
    const [selectedCountry, setSelectedCountry] = useState("");
    const [errors, setErrors] = useState({ name: "", country: "", taxId: "", gstId: "" });
    const [activeFail, setActiveFail] = useState(false);
    const [activeSuccess, setActiveSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState("");
    const [detectChange, setDetectChange] = useState(false);
    const [isExistingData, setIsExistingData] = useState(false)
    const [orgId, setOrgId] = useState("")
    const [uplaodFileName, setUploadFileName] = useState("")
    const [uploadMsg, setUplaodMsg] = useState()
    const [errorMsg, setErrorMsg] = useState("")
    const [uploadLoading, setUploadLoading] = useState(false)
    const userId = localStorage.getItem("userId")
    const [formData, setFormData] = useState({
        name: "",
        country: selectedCountry,
        taxId: "",
        gstId: "",
        user: userId,
        gstCertificate: ""
    });

    console.log("formData", formData)

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
        setDetectChange(true);


    };

    // const extractFileName = async () => {
    //     const s3Url = "https://footprints.testtube.io/1737610572444-Vector (1).svg"

    //     try {
    //         const response = await axios.get(s3Url, { responseType: "stream" });
    //         const tempDir = path.resolve(__dirname, "temp");
    //         if (!fs.existsSync(tempDir)) {
    //             fs.mkdirSync(tempDir);
    //         }


    //         // Extract the original file name by removing the timestamp
    //         const s3FileName = path.basename(s3Url); // e.g., "1687312839-originalfile.pdf"
    //         const originalFileName = s3FileName.replace(/^\d+-/, ""); // Removes the leading timestamp
    //         const filePath = path.join(tempDir, originalFileName); // Use the original file name


    //         // const filePath = path.join(tempDir, path.basename(s3Url));
    //         console.log(filePath);
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // }



    const extractFileName = (s3Url) => {
        if (s3Url) {
            try {
                const s3FileName = s3Url.split("/").pop(); // Get the last part of the URL
                const originalFileName = s3FileName.replace(/^\d+-/, ""); // Remove the leading timestamp
                console.log("Extracted File Name:", originalFileName);

                return originalFileName;
            } catch (error) {
                console.error("Error extracting file name:", error);
            }
        }
        else {
            return;
        }

    };





    const handleFileChange = async (event) => {
        const files = event.target.files;
        console.log("files", files.length)
        if (files.length > 0) {
            const file = files[0];
            if (file) {
                // Check if the file type is PDF
                if (file.type !== "application/pdf") {
                    setErrorMsg("*Invalid File Type: Please upload PDF file only");
                    event.target.value = null;
                    return;
                }
            }

            const maxSizeInBytes = 1 * 1024 * 1024; // 5MB
            if (file.size > maxSizeInBytes) {
                setErrorMsg("File size must be less than or equal to 1MB")
                return;
            }
            setDetectChange(true);
            setUploadLoading(true)
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileName", file.name);

            try {
                setErrorMsg("");

                const response = await fetch(`${baseUrl}/footprint/upload`, {
                    method: "POST",
                    body: formData,
                });

                if (response.ok) {
                    const result = await response.json();
                    console.log("File uploaded successfully:", result.fileUrl);
                    setFormData((prevData) => ({
                        ...prevData,
                        gstCertificate: result.fileUrl,
                    }));
                    setUplaodMsg("File uploaded successfully")
                    setUploadLoading(false)


                } else {
                    console.error("File upload failed:", response.statusText);
                    setUploadLoading(false)
                }
            } catch (error) {
                console.error("Error uploading file:", error);
                setUploadLoading(false)
            }
        }

    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (!formData.name && !formData.country && !formData.gstId && !formData.taxId) {
            setErrors({ name: "Company name cannot be empty", country: "Country name cannot be empty", gstId: "GstId cannot be empty", taxId: "TaxId cannot be empty" })
            setLoading(false)
            return;
        }

        if (!formData.name) {
            setErrors({ name: "Company name cannot be empty", country: "", gstId: "", taxId: "" })
            setLoading(false)
            return;
        }
        if (!formData.country) {
            setErrors({ country: "Country name cannot be empty", name: "", gstId: "", taxId: "" })
            setLoading(false)
            return;
        }
        if (!formData.taxId) {
            setErrors({ name: "", country: "", gstId: "", taxId: "Tax Id cannot be empty" })
            setLoading(false)
            return;
        }
        if (!formData.gstId) {
            setErrors({ country: "", gstId: "Gst Id cannot be empty", taxId: "", name: "" })
            setLoading(false)
            return;
        }
        if (isExistingData) {
            await updateOrganisation()
        }
        else {
            try {
                setErrors({ name: "", country: "", taxId: "", gstId: "" });

                const response = await axios.post(`${baseUrl}/organisation/`, formData);

                console.log("Post request successful!", response.data);



                setActiveSuccess(true);
                setValue("Organisation Successfully registered !!");
                setLoading(false)
            } catch (error) {
                if (error.response) {

                    console.log("error", error.response.data.msg);
                    setLoading(false)
                } else {
                    alert("Error In Submitting: " + error.message);
                    setLoading(false)

                }
            }
        }
    };

    const fetchOrganisationByUserId = async () => {

        try {
            const response = await axios.get(`${baseUrl}/organisation/${userId}`);
            console.log("response from Organisation data", response.data.organisations);
            const organisationExists = !!response.data?.organisations;

            setIsExistingData(organisationExists);

            setFormData({

                name: response.data?.organisations?.name || "",
                country: response.data?.organisations?.country,
                taxId: response.data?.organisations?.taxId,
                gstId: response.data?.organisations?.gstId,
                gstCertificate: response.data?.organisations?.gstCertificate || ""
            });
            setOrgId(response?.data?.organisations?._id)
        } catch (error) {
            console.log("Error in fetching data", error.response);
        }
    };


    const updateOrganisation = async () => {

        try {
            const response = await axios.put(`${baseUrl}/organisation/${orgId}`, {
                formData,
            });
            console.log("updated organisation details", response.data);

            setActiveSuccess(true);
            setValue("Details updated successfully");
            setLoading(false)

            // alert(response.data.msg);
        } catch (error) {
            // alert(error.response.data.msg);
            setLoading(false)
        }
    };



    const fetchCountry = async () => {

        try {
            const response = await fetch("https://restcountries.com/v3.1/all");
            const data = await response.json();
            // Extract country names and sort alphabetically
            const countryList = data.map((country) => ({
                name: country.name.common,
                code: country.cca2, // Country code (optional)
            })).sort((a, b) => a.name.localeCompare(b.name));
            console.log(countryList)
            setCountries(countryList);
        } catch (error) {
            console.error("Error fetching countries:", error);
        }
    };




    const handleCountryChange = (event) => {
        const newCountry = event.target.value;
        setSelectedCountry(newCountry); // Update the selectedCountry state
        setFormData((prevData) => ({
            ...prevData,
            country: newCountry, // Update the country field in formData
        }));
        setDetectChange(true)
    };


    useEffect(() => {
        fetchCountry()
        fetchOrganisationByUserId()
    }, [])

    useEffect(() => {
        if (formData?.gstCertificate) {
            const s3Url = formData.gstCertificate
            const fileName = extractFileName(s3Url);
            setUploadFileName(fileName)

            console.log("File Name:", fileName);
        }

    }, [formData]);


    useEffect(() => {
        if (activeFail) {
            setTimeout(() => {
                setActiveFail(false);
            }, 5000);
        }
        if (activeSuccess) {
            setTimeout(() => {
                setActiveSuccess(false);
                // navigate("/dashboard");

            }, 3500);
        }
    }, [activeFail, activeSuccess]);
    return (
        <>
            {activeFail && (
                <FailToast
                    errorValue={value}
                    activeFail={activeFail}
                    setActiveFail={setActiveFail}
                />
            )}
            {activeSuccess && (
                <SuccessToast
                    successValue={value}
                    activeSuccess={activeSuccess}
                    setActiveSuccess={setActiveSuccess}
                />
            )}

            <div class="h-full ">
                <div className='bg-white h-full  border border-layout-border shadow-[0px_1px_2px_0px_#0000000D] rounded-lg py-6 px-10'>
                    <div className='font-semibold text-3xl'>
                        Organisation
                    </div>
                    <div className='font-medium text-sm text-color-gray-light pt-1'>Manage your organisation from this section</div>
                    <div className="form space-y-2  mt-8">
                        <div>
                            <label for="name" class="block mb-2  text-base font-semibold text-auth-label">Company Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="Company Name" class="bg-white border focus:outline-none text-color-gray-light  sm:text-sm rounded-lg  block w-full p-3" />


                            {errors.name && (
                                <p className="text-error-msg font-medium text-sm pt-2">
                                    {errors.name}
                                </p>
                            )}

                        </div>


                        {/* <div className='relative'>
                            <label
                                htmlFor="country"
                                className="block mb-2 text-base  font-semibold text-auth-label"
                            >
                                Country
                            </label>
                            <select
                                id="country"
                                name="country"
                                value={formData.country || selectedCountry}
                                onChange={handleCountryChange}
                                className="bg-white border  focus:outline-none text-color-gray-light sm:text-sm rounded-lg block w-full p-3 pr-12 shadow-sm appearance-none"

                            >
                                <option value="">
                                    Select Country
                                </option>

                                {countries.map((country) => (
                                    <option key={country.code} value={country.name}>
                                        {country.name}
                                    </option>
                                ))}


                            </select>
                            <span className="absolute inset-y-0 right-3 top-1/2 bottom-2 flex justify-center items-center pointer-events-none text-gray-400">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"

                                >

                                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="#616161" />
                                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                                    <path d="M6.3439 7.75732L4.92969 9.17154L12.0007 16.2426L19.0718 9.17157L17.6576 7.75735L12.0008 13.4142L6.3439 7.75732Z" fill="black" fill-opacity="0.2" />
                                </svg>

                            </span>
                            {errors.country && (
                                <p className="text-error-msg font-medium text-sm pt-2">
                                    {errors.country}
                                </p>
                            )}
                           




                        </div> */}




                        <div className='relative'>
                            <label
                                htmlFor="country"
                                className="block mb-2 text-base  font-semibold text-auth-label"
                            >
                                Country
                            </label>

                            <div className="relative">



                                <select
                                    id="country"
                                    name="country"
                                    value={formData.country || selectedCountry}
                                    onChange={handleCountryChange}
                                    className="bg-white border max-h-20 focus:outline-none text-color-gray-light sm:text-sm rounded-lg block w-full p-3 pr-12 shadow-sm appearance-none"

                                >
                                    <option value="">
                                        Select Country
                                    </option>

                                    {countries.map((country) => (
                                        <option key={country.code} value={country.name}>
                                            {country.name}
                                        </option>
                                    ))}


                                </select>

                                <svg
                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                                    width="14"
                                    height="9"
                                    viewBox="0 0 14 9"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12.8334 1.5L7.00008 7.33333L1.16675 1.5"
                                        stroke="#111322"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                            </div>
                            {errors.country && (
                                <p className="text-error-msg font-medium text-sm pt-2">
                                    {errors.country}
                                </p>
                            )}


                        </div>



                        <div>
                            <label
                                htmlFor="email"
                                className="block mb-2 text-base font-semibold text-auth-label"
                            >
                                TAX ID
                            </label>
                            <input
                                type="text"
                                id="TaxId"
                                name="taxId"
                                value={formData.taxId}
                                onChange={handleChange}
                                className="bg-white border text-color-gray-light sm:text-sm rounded-lg focus:outline-none block w-full p-3 pr-20" // Added padding-right to make space for Verify text
                                placeholder="TAX ID"
                            />

                            {errors.taxId && (
                                <p className="text-error-msg font-medium text-sm pt-2">
                                    {errors.taxId}
                                </p>
                            )}




                        </div>

                        <div>
                            <label
                                htmlFor="email"
                                className="block mb-2 text-base font-semibold text-auth-label"
                            >
                                GST ID
                            </label>
                            <input
                                type="text"
                                id="GstId"
                                name="gstId"
                                value={formData.gstId}
                                onChange={handleChange}
                                className="bg-white border text-color-gray-light sm:text-sm rounded-lg focus:outline-none block w-full p-3 pr-20" // Added padding-right to make space for Verify text
                                placeholder="GST ID"
                            />

                            {errors.gstId && (
                                <p className="text-error-msg font-medium text-sm pt-2">
                                    {errors.gstId}
                                </p>
                            )}


                        </div>






                        <label class="block mb-2 text-base font-semibold text-auth-label  ">GST Certificate</label>

                        <div class="flex justify-center items-center ">

                            <label for="file-upload" class="w-full  p-6 bg-white border-2 border-dashed border-[#DCDFEA] rounded-lg cursor-pointer  ">
                                <div class="flex flex-col items-center justify-center">
                                    {!uploadLoading &&
                                        <svg width="64" height="32" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="64" height="32" rx="16" fill="#EFF1F5" />
                                            <path d="M32 18.6667V14.6667M32 14.6667L30 16M32 14.6667L34 16M26 12V19.2C26 19.9468 26 20.3199 26.1453 20.6051C26.2732 20.856 26.477 21.0603 26.7279 21.1882C27.0128 21.3334 27.386 21.3334 28.1313 21.3334H35.8687C36.614 21.3334 36.9867 21.3334 37.2716 21.1882C37.5225 21.0603 37.727 20.8561 37.8548 20.6052C38.0001 20.32 38.0001 19.9466 38.0001 19.1999L38.0001 14.1332C38.0001 13.3865 38.0001 13.0131 37.8548 12.7279C37.727 12.477 37.5226 12.2732 37.2717 12.1453C36.9865 12 36.6134 12 35.8667 12H32M26 12H32M26 12C26 11.2636 26.597 10.6667 27.3333 10.6667H29.783C30.1091 10.6667 30.2726 10.6667 30.426 10.7035C30.5621 10.7362 30.6919 10.7902 30.8112 10.8633C30.9457 10.9457 31.0612 11.0612 31.2917 11.2917L32 12" stroke="#5D6B98" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    }



                                    {uploadLoading ? (
                                        <div className="flex flex-col items-center">
                                            <svg
                                                className="animate-spin h-8 w-8 text-gray-400"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8v8H4z"
                                                ></path>
                                            </svg>
                                            <p className="mt-2 text-sm text-[#7D89B0]">Uploading...</p>
                                        </div>
                                    ) : uplaodFileName ? (
                                        <p className="mt-2 text-xs text-[#7D89B0]">
                                            Uploaded File: {uplaodFileName}
                                        </p>
                                    ) : (
                                        <>
                                            <p className="mt-2 text-xs text-[#7D89B0]">
                                                Upload your GST certificate in PDF format
                                            </p>
                                            <p className="mt-1 text-xs text-[#7D89B0]">Upload limit max 1MB</p>
                                        </>
                                    )}


                                </div>
                                <input id="file-upload" accept=".pdf" type="file" onChange={handleFileChange} class="hidden" />
                            </label>
                        </div>

                        {uploadMsg && <div className=" font-medium text-sm text-success-msg">{uploadMsg}</div>}
                        {errorMsg && <div className=" font-medium text-sm text-error-msg">{errorMsg}</div>}



                        <div className="flex justify-end pt-4">

                            <div className='flex flex-end space-x-2'>
                                <SettingButton text={"Save"} onClick={handleSubmit} loading={loading}
                                    // disabled={ !detectChange}
                                    {...(isExistingData ? { disabled: !detectChange } : {})}

                                />

                            </div>

                        </div>


                    </div>


                </div >




            </div >
        </>
    )
}

export default Organisation