import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { baseUrl,clientUrl } from '../../config';
import SuccessToast from '../Toast/SuccessToast';
import FailToast from '../Toast/FailToast';
import { encryptUserName } from "../../utils/encrypt"
const Share = ({ websiteId, footprintId, videoId, footprintName, handleCloseModal }) => {
    const [emails, setEmails] = useState([]);
    const [emailInput, setEmailInput] = useState("");
    const [loading, setLoading] = useState(false)
    const [copy, setCopy] = useState(false)
    const [activeFail, setActiveFail] = useState(false);
    const [activeSuccess, setActiveSuccess] = useState(false);
    const [value, setValue] = useState("");
    const [sharedEmails, setSharedEmails] = useState([])
    const [errorMessage, setErrorMessage] = useState()
    const [encryptedUserName, setEncryptedUserName] = useState('');


    const username = localStorage.getItem("username")
    const userId = localStorage.getItem("userId")


    // Handle encryption
    const handleEncrypt = () => {
        const encrypted = encryptUserName(username);
        setEncryptedUserName(encrypted);
        // console.log('Encrypted User name:', encrypted);
    };

    // Handle decryption



    let TestLink = `${clientUrl}/share-test/website/${websiteId}/footprint/${footprintId}/video/${videoId}?user=${encryptedUserName}`

  
    const [showAll, setShowAll] = useState(false);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleToggleShowAll = () => {
        setShowAll(!showAll);
    };

    const handleAddEmail = () => {
        if (!emailInput) {
            setErrorMessage("Email is required");
            return;
        }

        else if (!validateEmail(emailInput)) {
            setErrorMessage("Invalid email format");
            return;
        }

        else if (emails.includes(emailInput)) {
            setErrorMessage("This email is already added");
            return;
        }
        //    else if (sharedEmails.includes(emailInput)) {
        //         setErrorMessage("Test is already shared with this email");
        //         return;
        //       }

        setEmails([...emails, emailInput]);
        setEmailInput("");
        setErrorMessage("");
    };
    // const handleAddEmail = () => {
    //     if (emailInput && !emails.includes(emailInput)) {
    //         setEmails([...emails, emailInput]);
    //         setEmailInput("");
    //     }
    // };

    const handleDeleteEmail = (emailToDelete) => {
        setEmails(emails.filter((email) => email !== emailToDelete));
    };

    const handleCopy = () => {
        navigator.clipboard
            .writeText(TestLink)
            .then(() => {
                setCopy(true);
                setTimeout(() => {
                    setCopy(false);
                }, 2000);
            })


            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };

    const fetchVideo = async () => {
        const token = localStorage.getItem("Token")
        try {
            const response = await axios.get(`${baseUrl}/videos/single/${websiteId}/${videoId}`,

                {
                    headers: { authorization: `Bearer ${token}` },
                });

            console.log("Get request for video successful!------------------------------", response.data.sharedEmails);
            setSharedEmails(response.data.sharedEmails)

        } catch (error) {
            console.log("Error in fetching data", error.response);
        }
    };

    const handleShare = async () => {
        setLoading(true)
        if (emails.length > 0) {
            try {
                const response = await axios.put(
                    `${baseUrl}/videos/${videoId}/shareEmail`,
                    { emails: emails, footprintName: footprintName, username: username, link: TestLink },

                );
                console.log("response from shareEmail", response.data);
                setLoading(false)
                setActiveSuccess(true)
                setValue("Test shared successfully")



            } catch (error) {
                setLoading(false)
                setActiveFail(true)
                setValue("Error in sharing test")
            }

        }


    };

    useEffect(() => {
        fetchVideo()
        handleEncrypt()
    }, [])

    useEffect(() => {
        if (activeFail) {
            setTimeout(() => {
                setActiveFail(false);
            }, 5000);
        }
        if (activeSuccess) {
            setTimeout(() => {
                setActiveSuccess(false);
                handleCloseModal()
                setEmails([])

            }, 1000);
        }
    }, [activeFail, activeSuccess]);

    return (


        <>

            {activeFail && (
                <FailToast
                    errorValue={value}
                    activeFail={activeFail}
                    setActiveFail={setActiveFail}
                />
            )}
            {activeSuccess && (
                <SuccessToast
                    successValue={value}
                    activeSuccess={activeSuccess}
                    setActiveSuccess={setActiveSuccess}
                />
            )}
            <div className="alert-card-container ">
                <div
                    className=" overflow-auto inner-alert-container shadow-[0px_8px_8px_-4px_#10182808] w-[456px] min-h-[170px] gap-4 py-6 px-4 flex flex-col border bg-white rounded-lg"

                >
                    {/* <div
                        className=" overflow-auto inner-alert-container  w-[456px] min-h-[380px] gap-4 py-4 px-3 flex flex-col bg-white rounded-lg"

                    > */}
                    <div className=''>
                        <label
                            htmlFor="email"
                            className="block mb-2 text-base font-semibold text-auth-label"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}

                            placeholder="Email"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") handleAddEmail();
                            }}
                            className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none"
                        />
                        {errorMessage && (
                            <p className="text-error-msg font-medium text-xs pt-1">
                                {errorMessage}
                            </p>
                        )}
                    </div>


                    {emails.length > 0 && (
                        <div className="flex flex-wrap gap-2">
                            {emails.map((email, index) => (
                                <div
                                    key={index}
                                    className="flex items-center bg-gray-100 px-2.5 py-0.5 rounded"
                                    style={{
                                        maxWidth: "fit-content", // Ensures the chip doesn't take full width
                                    }}
                                >
                                    <span className="text-[#344054] text-sm font-medium mr-2">{email}</span>



                                    <svg className='cursor-pointer' onClick={() => handleDeleteEmail(email)} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 3L3 9M3 3L9 9" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>


                                </div>
                            ))}
                        </div>
                    )}





                    {sharedEmails?.length > 0 &&
                        <div className="outerSection flex-grow">
                            <div className="InnerSection">
                                <div className="text-alert-small-content text-sm font-medium">Shared with:</div>

                                <div>
                                    {sharedEmails?.length > 0 && (

                                        <div
                                            className={`flex flex-col gap-2 mt-2 ${sharedEmails.length > 5 ? "overflow-y-auto" : ""}`}
                                            style={{
                                                maxHeight: sharedEmails.length > 5 ? "150px" : "auto", // Limit height if more than 5 emails
                                            }}
                                        >
                                            {(showAll ? sharedEmails : sharedEmails.slice(0, 5)).map((email, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center"
                                                    style={{
                                                        maxWidth: "fit-content", // Ensures the chip doesn't take full width
                                                    }}
                                                >
                                                    <span className="text-footer text-sm font-medium">{email}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {sharedEmails.length > 5 && (
                                        <button
                                            onClick={handleToggleShowAll}
                                            className="text-footer text-sm font-medium underline mt-2"
                                        >
                                            {showAll
                                                ? "Show Less"
                                                : `and ${sharedEmails.length - 5} more people`}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>}

                    <div className="bottom-section ">
                        <div className=" flex justify-start items-center gap-1">
                            <button
                                disabled={emails.length===0}
                                onClick={handleShare}
                                className={`inline-flex items-center justify-center py-2 px-4  mr-2 text-sm font-semibold text-white  hover:bg-hover focus:outline-none bg-[#111322] rounded border  disabled:opacity-50 disabled:bg-secondary-button disabled:text-disable-secondary disabled:border-secondary-border disabled:cursor-not-allowed`}


                            >Share Test</button>

                            <div className="copy-container flex justify-center gap-2 items-center">
                                <button className="ml-2">
                                    <svg onClick={handleCopy} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className='cursor-pointer'>
                                        <path d="M7 7V4.2002C7 3.08009 7 2.51962 7.21799 2.0918C7.40973 1.71547 7.71547 1.40973 8.0918 1.21799C8.51962 1 9.08009 1 10.2002 1H15.8002C16.9203 1 17.4801 1 17.9079 1.21799C18.2842 1.40973 18.5905 1.71547 18.7822 2.0918C19.0002 2.51962 19.0002 3.07967 19.0002 4.19978V9.7998C19.0002 10.9199 19.0002 11.48 18.7822 11.9078C18.5905 12.2841 18.2839 12.5905 17.9076 12.7822C17.4802 13 16.921 13 15.8031 13H13M7 7H4.2002C3.08009 7 2.51962 7 2.0918 7.21799C1.71547 7.40973 1.40973 7.71547 1.21799 8.0918C1 8.51962 1 9.08009 1 10.2002V15.8002C1 16.9203 1 17.4801 1.21799 17.9079C1.40973 18.2842 1.71547 18.5905 2.0918 18.7822C2.5192 19 3.07899 19 4.19691 19H9.80355C10.9215 19 11.4805 19 11.9079 18.7822C12.2842 18.5905 12.5905 18.2839 12.7822 17.9076C13 17.4802 13 16.921 13 15.8031V13M7 7H9.8002C10.9203 7 11.4801 7 11.9079 7.21799C12.2842 7.40973 12.5905 7.71547 12.7822 8.0918C13 8.51921 13 9.079 13 10.1969L13 13" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />

                                    </svg>
                                </button>


                                <span onClick={handleCopy} className="text-light-black font-semibold text-sm"> {copy ? "Copied" : "Copy link"}
                                </span>
                            </div>

                        </div>
                    </div>



                </div>
            </div>


        </>

    )
}

export default Share