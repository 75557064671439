import React, { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { baseUrl } from "../../config";
import TestListPage from "../../pages/TestListPage";
import axios from 'axios';
import { Dropdown, Menu, Button } from 'antd';

import Drop_Down_Card from "../Card/Drop_Down_Card";
import SuccessToast from "../Toast/SuccessToast";
import FailToast from "../Toast/FailToast";

const Test_Info = () => {
  const { websiteId, footprintId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const record = state?.recordData;
  const [activeSuccess, setActiveSuccess] = useState(false);
  const [value, setValue] = useState("");
  const [activeFail, setActiveFail] = useState(false);


  const [openDropdown, setOpenDropdown] = useState("Status"); // Track the currently open dropdown

  const handleToggle = (title) => {
    setOpenDropdown((prev) => (prev === title ? null : title));
  };
  const goBackToList = () => {
    navigate(`/${websiteId}/test-list`);
  }

  const CustomDropdownIcon = ({ color = "#1570EF" }) => (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6668 1L6.00016 5.66667L1.3335 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const pauseSchedule = async (record) => {
    try {
      const response = await axios.post(`${baseUrl}/schedule/pause`, {
        footprintId: record.key,
      });
      console.log("API response for pauseSchedule:", response.data);
      setActiveSuccess(true);
      setValue(response.data.msg);
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };


  const deleteFootprint = async (record) => {
    if (record) {
      try {
        const response = await axios.delete(
          `${baseUrl}/footprint/${record?.key}`
        );
        console.log("response from footprint by id", response.data);

        navigate(`/${websiteId}/test-list`);



      } catch (error) {
        console.log("Error in fetching data", error.response);
      }
    }
  };

  const resumeSchedule = async (record) => {
    const token = localStorage.getItem("Token");
    if (token) {
      try {

        const response = await axios.post(
          `${baseUrl}/schedule/resume`,
          { footprintId: record.key },
          {
            headers: { authorization: `Bearer ${token}` },
          }
        );
        console.log("API response for resumeSchedule:", response.data);
        setActiveSuccess(true);
        setValue(response.data.message);
      } catch (error) {
        console.error("Error making API call:", error);
      }
    }
  };

  const handleRestart = (record) => {
    console.log("Row clicked for normal columns:", record);
    const data = { footprintId: record.key, websiteId: websiteId };
    navigate(`/Savefootprint/${record.key}/website/${websiteId}`, { state: { isRestart: true } });
  };

  const handleActionClick = async (record) => {
    if (record.status === "active") {
      console.log("record for stop button", record);
      pauseSchedule(record);
    }
    if (record.status === "stopped") {
      console.log("record for stop button", record);
      resumeSchedule(record);
    }
  };

  useEffect(() => {
    if (activeFail) {
      setTimeout(() => {
        setActiveFail(false);
      }, 3000);
    }
    if (activeSuccess) {
      setTimeout(() => {
        setActiveSuccess(false);
        const data = { websiteId: websiteId };
        navigate(`/${websiteId}/test-list`, { state: data });
      }, 2000);
    }
  }, [activeFail, activeSuccess]);

  return (
    <>
      {activeSuccess && (
        <SuccessToast
          successValue={value}
          activeSuccess={activeSuccess}
          setActiveSuccess={setActiveSuccess}
        />
      )}
      {activeFail && (
        <FailToast
          errorValue={value}
          activeFail={activeFail}
          setActiveFail={setActiveFail}
        />
      )}
      <div className="header-section">
        <div className="info">
          <div className="flex flex-col gap-6">
            <div className="guide-info text-color-gray-light flex gap-4 mx-2 items-center " >
             <span className=" flex gap-4 mx-2 items-center cursor-pointer"onClick={goBackToList}>
              <svg className="cursor-pointer" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 9L1 5L5 1" stroke="#111322" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              <h2 className='font-semibold cursor-pointer  text-xl' >Back to All Tests</h2>
              </span>


            </div>

            <div className="test-heading-container gap-6 flex items-center  ">
              <div className="title text-color-gray-light text-2xl font-semibold">Test {state?.footprintNumber.substr(2)} - {state?.footprintName}</div>
              {/* <div className="status-dropdown text-2xl">Running</div> */}
              <div className="flex items-center">
                {/* Capsule with dropdown inside */}
                <div
                  className={`flex items-center justify-between px-3 gap-1 py-1 rounded-[99px] text-xs font-semibold w-max ${record?.status === "active"
                    ? "bg-blue text-blue border border-blue"
                    : record?.status === "complete"
                      ? "bg-green text-green border border-green "
                      : "bg-layout text-dark-gray border border-status-border"
                    }`}
                >
                  {/* Status Text */}
                  <span className="">
                    {record?.status === "active"
                      ? "Active"
                      : record?.status === "inactive"
                        ? "Draft"
                        : record?.status === "stopped"
                          ? "Paused"
                          : "Completed"}
                  </span>

                  <Dropdown
                    menu={{
                      items: [
                        {
                          key: "1",
                          // label: `${record?.fpName}`,
                          label: (() => {
                            const text = `${record?.fpName}`
                            const maxChars = 15;
                            return text.length > maxChars ? text.substring(0, maxChars) + "..." : text;
                          })(),
                          style: {
                            backgroundColor: "#EFF1F5",
                            color: "#4A5578",
                            fontSize: "0.875rem",
                            fontWeight: "600",
                            borderRadius: "8px 8px 0 0",
                            width: "182px",
                            marginLeft: "-4px",
                            marginRight: "-4px",
                            marginTop: "-5px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            paddingLeft: "12px",
                            paddingRight: "12px",


                          },
                        },


                        ...(record?.status === "active"
                          ? [
                            {
                              key: "2",
                              label: "Pause",
                              onClick: () => handleActionClick(record),
                              style: {
                                color: "var(--color-gray-light)",
                                fontSize: "0.875rem",
                                fontWeight: "600",
                                padding: "12px",
                                marginLeft: "-4px",
                                marginRight: "-4px",
                                // borderRadius: "0px"
                                marginBottom: "-4px",

                                // borderRadius: "0px"

                                borderBottomRightRadius: "8px",
                                borderBottomLeftRadius: "8px",
                                borderTopLeftRadius: "0px",
                                borderTopRightRadius: "0px"

                              },
                            },
                          ]
                          : record?.status === "stopped"
                            ? [
                              {
                                key: "3",
                                label: "Run",
                                onClick: () => handleActionClick(record),
                                style: {
                                  color: "var(--color-gray-light)",
                                  fontSize: "0.875rem",
                                  fontWeight: "600",
                                  padding: "12px",
                                  marginLeft: "-4px",
                                  marginRight: "-4px",
                                  // borderRadius: "0px"
                                  marginBottom: "-4px",

                                  // borderRadius: "0px"

                                  borderBottomRightRadius: "8px",
                                  borderBottomLeftRadius: "8px",
                                  borderTopLeftRadius: "0px",
                                  borderTopRightRadius: "0px"
                                },
                              },
                            ]
                            : record?.status === "complete"
                              ? [
                                {
                                  key: "4",
                                  label: "Restart",
                                  onClick: () => handleRestart(record),
                                  style: {
                                    color: "var(--color-gray-light)",
                                    fontSize: "0.875rem",
                                    fontWeight: "600",
                                    padding: "12px",
                                    marginLeft: "-4px",
                                    marginRight: "-4px",
                                    marginBottom: "-4px",
                                    // borderRadius: "0px"
                                    borderBottomRightRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                    borderTopLeftRadius: "0px",
                                    borderTopRightRadius: "0px"
                                  },
                                },
                              ]
                              : []),

                        // {
                        //   key: "6",
                        //   label: "Delete",
                        //   onClick: () => deleteFootprint(record),
                        //   style: {
                        //     color: "var(--color-gray-light)",
                        //     fontSize: "0.875rem",
                        //     fontWeight: "600",
                        //     padding: "12px", // Add padding
                        //     marginLeft: "-4px",
                        //     marginRight: "-4px",
                        //     marginBottom: "-4px",
                        //     borderBottomRightRadius: "8px",
                        //     borderBottomLeftRadius: "8px",
                        //     borderTopLeftRadius: "0px",
                        //     borderTopRightRadius: "0px"
                        //   },
                        // },
                      ],
                    }}
                    trigger={["click"]}
                  >
                    <Button
                      type="text"
                      size="small"
                      icon={
                        <CustomDropdownIcon
                          color={record?.status === "active" ? "#1570EF" : record?.status === "complete" ? "#039855" : "#4A5578"}
                        />
                      }
                      className="p-0"
                    />
                  </Dropdown>

                </div>
              </div>
            </div>



            <div className="test-status-section">
              <Drop_Down_Card
                title="Status"
                isOpen={openDropdown === "Status"}
                onToggle={handleToggle}
                footprintName={state?.footprintName}
              />
            </div>
            <div className="test-summary-container">
              <Drop_Down_Card
                title="Summary"
                isOpen={openDropdown === "Summary"}
                onToggle={handleToggle}
              />
            </div>
            <div className="test-details-container">
              <Drop_Down_Card
                title="Details"
                isOpen={openDropdown === "Details"}
                onToggle={handleToggle}
              />
            </div>



          </div>
        </div>
      </div>
    </>
  );
};

export default Test_Info;
