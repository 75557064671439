import React, { useEffect, useState } from "react";
import FloatingInput from "../Input/FloatingInput";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SuccessToast from "../Toast/SuccessToast";
import { baseUrl } from "../../config";
import Intercom from "@intercom/messenger-js-sdk";
import "./card.css";
import Input from "../Input/Input";
import Button from "../Button/Button";
const Test_Schedule_Card = ({
    footPrint,
    websiteId,
    formData,
    setFormData,
    status = false

}) => {
    const navigate = useNavigate();
    const [activeFail, setActiveFail] = useState(false);
    const [activeSuccess, setActiveSuccess] = useState(false);
    const [value, setValue] = useState("");
    const [selection, setSelection] = useState("frequency");
    const [isRestart, setIsRestart] = useState(status || false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [loading, setLoading] = useState(false);

    window.intercomSettings = {
        app_id: "x5w0eskb",
        custom_launcher_selector: "#help",
    };

    console.log("status from  isRestart ", isRestart)

    const today = new Date().toISOString().split("T")[0];

    const [form, setForm] = useState({
        interval: "",
        noOfTimes: "",
        footprintId: "",
        endDate: "",
    });


    useEffect(() => {
        setForm({
            interval: "",
            noOfTimes: "",
            footprintId: footPrint && footPrint?._id,
            endDate: "",
        });
    }, [footPrint]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setForm((prevForm) => ({
            ...prevForm,
            [name]: value || "",  // Reset to empty string if placeholder is selected
        }));
    };


    const handleSelectionChange = (event) => {
        const newSelection = event.target.value;
        setSelection(newSelection);
    };

    const openScheduleBox = () => {
        setShowSchedule(true);
    }


    const isButtonDisabled = () => {
        if (!form.interval) return true;
        if (selection === "frequency" && !form?.noOfTimes) return true;
        if (selection === "day" && !form?.endDate) return true;
        return false;
    }

    // const handleSchedule = async (e) => {
    //     e.preventDefault();

    //     const token = localStorage.getItem("Token");

    //     console.log("Token", form);

    //     if (form.footprintId && form.interval && (form.noOfTimes || form.endDate)) {
    //         const payload = {
    //             scheduleForm: form,
    //             footprintForm: formData,
    //             isRestart
    //         };
    //         if (token) {
    //             try {

    //                 const response = await axios.post(`${baseUrl}/schedule`, payload, {
    //                     headers: { authorization: `Bearer ${token}` },
    //                 });

    //                 console.log("response", response.data);
    //                 // toast.success(response.data.message);
    //                 setActiveSuccess(true);
    //                 setValue(response.data.message);
    //             } catch (error) {
    //                 // toast.warning(error.response.data.msg);
    //                 console.log("error", error);
    //             }
    //         } else {
    //             navigate("/signIn");
    //         }
    //     }
    //     else {
    //         alert("please enter all fields");
    //     }

    //     console.log("formData", formData);
    // };



    const handleSchedule = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loader
    
        const token = localStorage.getItem("Token");
    
        console.log("Token", form);
    
        if (form.footprintId && form.interval && (form.noOfTimes || form.endDate)) {
            const payload = {
                scheduleForm: form,
                footprintForm: formData,
                isRestart
            };
            if (token) {
                try {
                    const response = await axios.post(`${baseUrl}/schedule`, payload, {
                        headers: { authorization: `Bearer ${token}` },
                    });
    
                    console.log("response", response.data);
                    // toast.success(response.data.message);
                    setActiveSuccess(true);
                    setValue(response.data.message);
                } catch (error) {
                    // toast.warning(error.response.data.msg);
                    console.log("error", error);
                } finally {
                    setLoading(false); // Stop loader
                }
            } else {
                setLoading(false); // Stop loader before navigating
                navigate("/signIn");
            }
        }
        else {
            setLoading(false); // Stop loader before showing alert
            alert("please enter all fields");
        }
    
    };
    

    const handleSaveSchedule = () => {
        navigate(`/${websiteId}/test-list`);
    };

    useEffect(() => {
        if (activeFail) {
            setTimeout(() => {
                setActiveFail(false);
            }, 3000);
        }
        if (activeSuccess) {
            setTimeout(() => {
                setActiveSuccess(false);
                // navigate("/footprint-status-list", { state: data });
                navigate(`/${websiteId}/test-list`);
            }, 2000);
        }
    }, [activeFail, activeSuccess]);

    return (
        <>
            {activeSuccess && (
                <SuccessToast
                    successValue={value}
                    activeSuccess={activeSuccess}
                    setActiveSuccess={setActiveSuccess}
                />
            )}
            <div className="container">
                {/** h-[80vh] removed */}
                <div className="wrapper  w-full">

                    <div className="top-section flex justify-between items-center mx-4 my-4">
                        <div className="content text-color-gray-light font-semibold text-xl ">
                            <h3 className="text-3xl">New Test</h3>
                            <p className="text-sm font-medium ">Start by filling out this form and create a new test</p>
                        </div>
                    </div>
                    <Input
                        footPrint={footPrint}
                        isReadOnly={true}
                        isEditable={true}
                        formData={formData}
                        setFormData={setFormData}


                    />

                    {
                        footPrint?.capturedSelectors?.length > 0 && (
                            <div className="count-info">
                                <div className="inner-count-info flex gap-2 text-xs  mx-4">
                                    <div className="content text-gray-100  mt-1">
                                        <button
                                            //  className="button bg-white border rounded-md text-black px-1 mt-2"
                                            className=" button bg-white border border-link-border rounded-[4px] text-color-gray-light text-[10px] px-1.5 py-0.5  tracking-widest font-normal"
                                        >
                                            {footPrint?.capturedSelectors?.length} steps
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    }


                    {
                        showSchedule && (
                            <div className="mid-section rounded-xl mx-4">
                                <div className="selection-box  mt-4">
                                    <div className="relative w-full text-xs">
                                        <div className="flex gap-8 text-[16px] text-color-gray-light">
                                            <div className=" font-semibold text-2xl">
                                                Schedule your Test
                                            </div>
                                            <label className="flex items-center ">
                                                <input
                                                    type="radio"
                                                    name="option"
                                                    value="frequency"
                                                    checked={selection === "frequency"}
                                                    onChange={handleSelectionChange}
                                                    className="custom-radio mr-2"
                                                />
                                                Frequency
                                            </label>
                                            <label className="flex items-center">
                                                <input
                                                    type="radio"
                                                    name="option"
                                                    value="day"
                                                    checked={selection === "day"}
                                                    onChange={handleSelectionChange}
                                                    className="custom-radio mr-2"
                                                />
                                                Date
                                            </label>


                                        </div>
                                    </div>
                                </div>






                                <div className="flex space-x-3  my-6">
                                    <div className="relative w-full text-[16px] ">
                                        <label htmlFor="interval" className="block text-color-gray-light font-semibold mb-1">
                                            Select Interval
                                        </label>
                                        <div className="relative">
                                            <select
                                                id="interval"
                                                className="block w-full px-3 py-3  rounded-lg border border-link-border appearance-none focus:outline-none hover:cursor-pointer"
                                                name="interval"
                                                onChange={handleChange}
                                            >
                                                <option value="">Select the interval</option>
                                                <option value="30 minutes">Every 30 minutes</option>
                                                <option value="60 minutes">Every 60 minutes</option>
                                                <option value="240 minutes">Every 4 hours</option>
                                                <option value="480 minutes">Every 8 hours</option>
                                                <option value="720 minutes">Every 12 hours</option>
                                            </select>

                                            <svg
                                                className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                                                width="14"
                                                height="9"
                                                viewBox="0 0 14 9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12.8334 1.5L7.00008 7.33333L1.16675 1.5"
                                                    stroke="#111322"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </div>
                                        <p className="text-xs text-input-remark font-normal my-1">This is the page that you want the test to start from.</p>

                                    </div>


                                    {selection === "frequency" ? (
                                        <div className="relative w-full text-[16px] ">
                                            <label htmlFor="floatingSelect1" className="block text-color-gray-light font-semibold mb-1">
                                                Select Frequency
                                            </label>
                                            <div className="relative">
                                                <select
                                                    id="floatingSelect1"
                                                    className="block w-full px-3 py-3 rounded-lg border border-link-border appearance-none focus:outline-none hover:cursor-pointer h-full"
                                                    name="noOfTimes"
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select the frequency</option>
                                                    <option value="5">5 times</option>
                                                    <option value="10">10 times</option>
                                                    <option value="15">15 times</option>
                                                    <option value="20">20 times</option>
                                                    <option value="25">25 times</option>
                                                </select>




                                                <svg
                                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none"
                                                    width="14"
                                                    height="9"
                                                    viewBox="0 0 14 9"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M12.8334 1.5L7.00008 7.33333L1.16675 1.5"
                                                        stroke="#111322"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                            <p className="text-xs text-input-remark font-normal my-1">This is the page that you want the test to start from.</p>


                                        </div>
                                    ) : (
                                        <div className="relative w-full text-[16px] h-12">  {/** */}
                                            <label htmlFor="floatingDate" className="block text-color-gray-light font-semibold mb-1">
                                                Select Date
                                            </label>
                                            <input
                                                id="floatingDate"
                                                type="date"
                                                className="block w-full px-3 py-6 rounded-lg border border-link-border hover:cursor-pointer text-md focus:outline-none h-full"
                                                name="endDate"
                                                min={today}
                                                onClick={(e) => e.target.showPicker()} // Forces the calendar to open
                                                onChange={handleChange}
                                            />
                                            <p className="text-xs text-input-remark my-1 font-normal">This is the page that you want the test to start from.</p>

                                        </div>
                                    )}
                                </div>




                            </div>
                        )
                    }

                    <div className="my-4">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <div className="ml-4 flex items-center gap-6 my-2 ">
                                    {
                                        !showSchedule ? (<>
                                            <Button id="button_schedule_now" text="Schedule Now" onClick={openScheduleBox}
                                            />

                                            <Button id="button_save_sch" primary={false} text="Save as Draft" onClick={handleSaveSchedule}
                                            />
                                        </>) :
                                            (
                                                <>
                                                    <Button id="" text="Run Test" onClick={handleSchedule} disabled={isButtonDisabled()} loading={loading}
                                                    />


                                                    <Button id="button_save_sch" primary={false} text="Save as Draft" onClick={handleSaveSchedule}
                                                    />


                                                </>

                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
};

export default Test_Schedule_Card;

