

import React from 'react';
import Test_Schedule from '../Footprint/Test_Schedule';
import Test_Info_Details_Card from './Test_Info_Details_Card';
import Test_Summary from '../Footprint/Test_Summary';
import Test_Status from '../Footprint/Test_Status';

const Drop_Down_Card = ({ title, isOpen, onToggle,hide=false ,footprintName ,anonymousData}) => {
    const renderContent = () => {
        switch (title) {
            case "Details":
                return <Test_Info_Details_Card hide={hide} anonymousData={anonymousData} />;
            case "Summary":
                return <Test_Summary />;
            case "Status":
                return <Test_Status footprintName={footprintName} />;
            default:
                return null;
        }
    };

    return (
        <div className="drop-down-container">
            <div className="drop-down-section flex flex-col gap-6 md:bg-white  md:rounded-lg md:border md:border-layout-border md:shadow-[0px_1px_2px_0px_#0000000D] md:p-6">
                <div className="top-section flex justify-between items-center cursor-pointer"                         onClick={() => onToggle(title)}                >
                    <div className="title text-color-gray-light text-2xl font-semibold">{title}</div>
                    <svg
                        // onClick={() => onToggle(title)}
                        className={`cursor-pointer transform transition-transform duration-300 ${
                            isOpen ? "rotate-180" : "rotate-0"
                        }`}
                        width="14"
                        height="9"
                        viewBox="0 0 14 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.8332 1.5L6.99984 7.33333L1.1665 1.5"
                            stroke="#111322"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                {isOpen && <div className="drop-down-content">{renderContent()}</div>}
            </div>
        </div>
    );
};

export default Drop_Down_Card;
